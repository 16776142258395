import {
  List,
  Typography,
  ListItem,
} from "@mui/material";
import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

// Styled components
const FooterSection = styled.footer`
  font-family: roboto;
  background-color: #f9f9f9;
  padding: 3rem 5rem;
  text-align: center;

  @media screen and (min-device-width: 0px) and (max-device-width: 768px) {
    padding: 3rem 0rem;
  }
`;

const ContainerFooter = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FooterRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 2rem;

  @media screen and (min-device-width: 0px) and (max-device-width: 768px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  @media screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
    grid-template-columns: repeat(4, 1fr) !important;
  }
`;

const ColumnFooter = styled.div`
  ul:not(.contact-class) {
    gap: 0rem;
  }
      
  ul {
    gap: 1rem;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    

    @media screen and (min-device-width: 0px) and (max-device-width: 768px) {
      align-items: center;
    }
    
    
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      align-items: center;
    }
  }
  
  ul h3 {
    font-size: 1rem;
  }
  
  ul li {
    list-style: none;
  }
  
  ul .footer-title-section {
    height: 48px;
    display: flex;
    align-items: center;

    @media screen and (min-device-width: 0px) and (max-device-width: 768px) {
      margin: 0 auto;
    }

  }

  ul li a {
    font-weight: 400;
    line-height: 24px;
    font-size: 1rem;
    color: #616161;
    text-decoration: none;
  }

  &:nth-child(1) ul a img {
    min-height: 39px;
    max-width: 140px;
  }

  &:nth-child(2) ul a img {
    min-height: 29px;
    max-width: 120px;
  }

  &:nth-child(3) ul a img {
    min-height: 50px;
    max-width: 60px;
  }
`;

const SocialMedia = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  gap: 1rem;
  margin-top: 2rem;

  .social-media--icon {
    width: 32px;
    height: 32px;
  }

  > .list-social {
    display: flex;
    gap: 2rem;
    margin: 0 1rem;
    flex-wrap: wrap;
    margin-top: 2rem;

    @media screen and (min-device-width: 0px) and (max-device-width: 768px) {
      justify-content: center;
    }
  }

  p {
    font-size: 0.75rem;
    line-height: 16px;
    font-weight: 400;
    text-align: left;
    color: black;
    margin-top: 2rem;

    @media screen and (min-device-width: 0px) and (max-device-width: 768px) {
      margin: 2rem 0;
    }
  }

  @media screen and (min-device-width: 1920px) {
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
  }
`;

// React Component
const Footer = () => {
  return (
    <FooterSection>
      <ContainerFooter>
        <FooterRow>
          {/* First Column */}
          <ColumnFooter>
            <List>
              <ListItem><Link className="footer-title-section" href="#"><img src="https://explorer.zeniq.net/_next/static/media/zeniq_logo.35b0f820.svg" alt=""/></Link></ListItem>
              <ListItem><Link href="https://faucet.zeniq.net/">ZENIQ Faucet</Link></ListItem>
              <ListItem><Link href="https://tokens.zeniq.net/">ZENIQ Tokens</Link></ListItem>
              <ListItem><Link href="https://swap.zeniq.net/">ZENIQ Swap</Link></ListItem>
              <ListItem><Link href="https://zeniq.net/">Block Explorer</Link></ListItem>
              <ListItem><Link href="https://smart.zeniq.net/">Smart Chain Explorer</Link></ListItem>
              <ListItem><Link href="https://zeniq.net/">ZENIQ Hub</Link></ListItem>
              <ListItem><Link href="https://www.zeniq.dev/">ZENIQ Development</Link></ListItem>
            </List>
          </ColumnFooter>

          {/* Second Column */}
          <ColumnFooter>
            <List>
              <ListItem><Link className="footer-title-section" href="#"><img src="https://explorer.zeniq.net/_next/image/?url=%2F_next%2Fstatic%2Fmedia%2Fnomo.efa448af.png&w=256&q=75" alt=""/></Link></ListItem>
              <ListItem><Link href="https://apps.apple.com/app/nomo-powered-by-zeniq/id1660767200/">Get it on the App Store</Link></ListItem>
              <ListItem><Link href="https://play.google.com/store/apps/details?id=app.nomo">Get it on Google Play</Link></ListItem>
              <ListItem><Link href="https://dev.nomo.app/">Nomo Development</Link></ListItem>
            </List>
          </ColumnFooter>

          {/* Third Column */}
          <ColumnFooter>
            <List>
              <ListItem><Link className="footer-title-section" href="#"><img src="https://explorer.zeniq.net/_next/static/media/zencon_logo.3841144b.svg" alt=""/></Link></ListItem>
              <ListItem><Link href="https://www.facebook.com/zenconhackathon">ZENCON on Facebook</Link></ListItem>
              <ListItem><Link href="https://www.linkedin.com/company/zencon-hackathon">ZENCON on LinkedIn</Link></ListItem>
              <ListItem><Link href="https://www.youtube.com/channel/UC6aOJu0aYPpBhg8a9YGCgWQ">ZENCON on YouTube</Link></ListItem>
            </List>
          </ColumnFooter>

          {/* Fourth Column */}
          <ColumnFooter>
            <List className="contact-class">
              <h3 style={{ color: '#757575' }}>CONTACT US</h3>
              <liListItem style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <img src="https://explorer.zeniq.net/_next/static/media/mail.38e1d3e5.svg" alt=""/>
                <a href="mailto:support@zeniq.com">support@zeniq.com</a>
              </liListItem>
            </List>
          </ColumnFooter>
        </FooterRow>

        {/* Social Media Section */}
        <SocialMedia>
          <div className="list-social">
            {/* Facebook Icon */}
            <Link href="https://www.facebook.com/ZENIQcorporation">
              <img className="social-media--icon" src="https://explorer.zeniq.net/_next/static/media/facebook.f4816260.svg" alt="Facebook"/>
            </Link>

            {/* Linktree Icon */}
            <Link href="https://linktr.ee/zeniq/">
              <img className="social-media--icon" src="https://explorer.zeniq.net/_next/static/media/brand-linktree.6927b6e6.svg" alt="Linktree"/>
            </Link>

            {/* Discord Icon */}
            <Link href="https://discord.gg/Au7EETSnDg">
              <img className="social-media--icon" src="https://explorer.zeniq.net/_next/static/media/discord.d6618802.svg" alt="Discord"/>
            </Link>

            {/* YouTube Icon */}
            <Link href="https://www.youtube.com/c/ZENIQTech">
              <img className="social-media--icon" src="https://explorer.zeniq.net/_next/static/media/youtube.afb4dccc.svg" alt="YouTube"/>
            </Link>

            {/* Twitter Icon */}
            <Link href="https://twitter.com/Zeniq_corp">
              <img className="social-media--icon" src="https://explorer.zeniq.net/_next/static/media/twitter.171949e2.svg" alt="Twitter"/>
            </Link>

            {/* Telegram Icon */}
            <Link href="https://t.me/zeniq_hub">
              <img className="social-media--icon" src="https://explorer.zeniq.net/_next/static/media/telegram.611d2096.svg" alt="Telegram"/>
            </Link>
          </div>
          <Typography>
            ZENIQ does not provide any financial services, offerings or financial advice and is not licensed or regulated by the Dubai Financial Services Authority DFSA
          </Typography>
        </SocialMedia>
      </ContainerFooter>
    </FooterSection>
  );

};

export default Footer;
