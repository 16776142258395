import { Box, Typography } from "@mui/material";
import React from "react";

const GeneralChip = ({ value, type, varient }) => {
  return (
    <Box
      sx={{
        backgroundColor:
          type == "success" ? "#CFB06D" : "rgba(221, 84, 84, 0.15)  ",
        paddingX: "1rem",
        paddingY: ".25rem",
        borderRadius: "3px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant={varient}>{value}</Typography>
    </Box>
  );
};

export default GeneralChip;
