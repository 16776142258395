import {
  Box,
  Container,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import "./style.css";

const TransactionMethod = ({ name, params, input }) => {
  const [inputView, setInputView] = useState("default ");
  const [rawMethod, setRawMethod] = useState("" | undefined);
  const [rawInputData, setRawInputData] = useState("" | undefined);
  const [utf8, setUtf8] = useState("" | undefined);

  useEffect(() => {
    if (input) {
      setRawMethod(input.substring(0, 10));
      const values = input.substring(10, input.length);
      let index = 0;

      const splitInput = [];
      while (index < values.length) {
        splitInput.push(
          values.substring(index, Math.min(index + 64, values.length))
        );
        index = index + 64;
      }

      setRawInputData("");

      if (name && params) {
        setRawInputData((data) => data + `${name}`);

        if (params) {
          const length = params.length;
          setRawInputData((data) => data + `(`);
          params.forEach((param, index) => {
            setRawInputData((data) => data + `${param.type} ${param.name}`);
            if (index + 1 < length) {
              setRawInputData((data) => data + `, `);
            }
          });
          setRawInputData((data) => data + `)`);
        }

        setRawInputData((data) => data + `\n\n`);
      } else {
      }

      setRawInputData((data) => data + `${rawMethod}\n`);

      splitInput.forEach((value, index) => {
        setRawInputData((data) => data + `[${index}]: ${value}\n`);
      });

      setUtf8(Web3.utils.toAscii(input));

      if (params?.length) {
        setInputView("decoded");
      }
    }
  }, [input]);

  const handleInputView = (e, newInputView) => {
    setInputView(newInputView);
  };

  return (
    <Container maxWidth="xl" sx={{ paddingY: "2rem" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="singlePageTitle">Input</Typography>

        <ToggleButtonGroup
          value={inputView}
          exclusive
          onChange={handleInputView}
          aria-label="input view"
        >
          <ToggleButton value="default " aria-label="default input">
            <Typography>Default </Typography>
          </ToggleButton>
          <ToggleButton value="utf8" aria-label="utf8 input">
            <Typography>UTF-8</Typography>
          </ToggleButton>

          {params && (
            <ToggleButton value="decode" aria-label="decode input">
              <Typography>DECODE</Typography>
            </ToggleButton>
          )}

          <ToggleButton value="orginal" aria-label="orginal input">
            <Typography>Original</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box sx={{ marginTop: "2rem" }}>
        {inputView == "default " ? (
          <TextareaAutosize
            value={rawInputData}
            minRows={1}
            maxRows={Infinity}
            disabled
            style={{ width: "100%", resize: "none" }}
          />
        ) : inputView == "utf8" ? (
          <TextareaAutosize
            value={utf8}
            minRows={1}
            maxRows={Infinity}
            disabled
            style={{ width: "100%", resize: "none" }}
          />
        ) : (
          <TextareaAutosize
            value={input}
            minRows={1}
            maxRows={Infinity}
            disabled
            style={{ width: "100%", resize: "none" }}
          />
        )}
      </Box>
    </Container>
  );
};

export default TransactionMethod;
