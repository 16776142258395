import {
  Box,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTxsByBlock } from "../../services/transaction/transaction-resource.service";
import TruncateAddress from "../truncate-address.component";
import WEI from "../wei.component";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../utils/constants/constants";
import { Link } from "react-router-dom";
import TransactionFromToChip from "../transactions/transaction-from-to-chip.component";
import TableCellCopy from "../table-cell-copy.component";

const BlockTransactions = ({ blockId }) => {
  const [tableCurrentPage, setTableCurrentPage] = useState(0);
  const [tableCurrentSize, setTableCurrentSize] = useState(10);
  const [transaction, setTransaction] = useState(undefined);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getTxsByBlock(blockId, tableCurrentPage + 1, tableCurrentSize).then(
      (resp) => {
        setTransaction(resp);
        setLoading(false);
      }
    );
  }, [tableCurrentPage, tableCurrentSize, blockId]);

  const handleChangePage = (event, newPage) => {
    setTableCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setTableCurrentSize(parseInt(event.target.value, 10));
    setTableCurrentPage(0);
  };

  return (
    <Container maxWidth="xl" sx={{ paddingY: "2rem" }}>
      {!loading && transaction && (
        <>
          <Typography variant="singlePageTableTitle">
            {transaction.total} transactions in block {transaction.blockId}
          </Typography>

          <TableContainer sx={{ marginTop: "2rem" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Hash</StyledTableCell>
                  <StyledTableCell>Method</StyledTableCell>
                  <StyledTableCell>From</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>To</StyledTableCell>
                  <StyledTableCell>Value</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {transaction.transactions.map((singleTransaction) => (
                  <StyledTableRow
                    key={singleTransaction.data?.hash}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <TableCellCopy
                        type={"Hash"}
                        content={singleTransaction.data?.hash}
                      >
                        <Link
                          to={`/transaction/${singleTransaction.data?.hash}`}
                        >
                          {
                            <TruncateAddress
                              address={singleTransaction.data?.hash}
                            />
                          }
                        </Link>
                      </TableCellCopy>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Box
                        sx={{
                          backgroundColor: "rgba(218, 165, 32, 0.42)",
                          borderRadius: "3px",
                          paddingX: "1rem",
                          paddingY: ".25rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {singleTransaction.method
                          ? singleTransaction.method?.name
                          : "Transaction"}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TableCellCopy
                        type={"Address"}
                        content={singleTransaction.data?.from}
                      >
                        <Link to={`/address/${singleTransaction.data?.from}`}>
                          {
                            <TruncateAddress
                              address={singleTransaction.data?.from}
                            />
                          }
                        </Link>
                      </TableCellCopy>
                    </StyledTableCell>
                    <StyledTableCell>
                      <TransactionFromToChip method={singleTransaction.type} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TableCellCopy
                        type={"Address"}
                        content={singleTransaction.data?.to}
                      >
                        <Link to={`/address/${singleTransaction.data?.to}`}>
                          {
                            <TruncateAddress
                              address={singleTransaction.data?.to}
                            />
                          }
                        </Link>
                      </TableCellCopy>
                    </StyledTableCell>
                    <StyledTableCell>
                      {
                        <WEI
                          value={singleTransaction.data?.value}
                          arg={"ether"}
                        />
                      }{" "}
                      ZENIQ
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    count={transaction.total}
                    rowsPerPage={tableCurrentSize}
                    page={tableCurrentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  );
};

export default BlockTransactions;
