import React from "react";
import { isString } from "lodash";
import { toChecksumAddress } from "ethereum-checksum-address";
const ChecksumAddress = ({ value }) => {
  return (
    <>
      {isString(value) && value.startsWith("0x")
        ? toChecksumAddress(value)
        : value}
    </>
  );
};

export default ChecksumAddress;
