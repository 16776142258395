import { method } from "lodash";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function TransactionFromToChip({ method, fromToLabel }) {
  return (
    <Box
      sx={{
        backgroundColor: "#CFB06D",
        paddingX: "1rem",
        paddingY: ".25rem",
        borderRadius: "3px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {method == "transaction" ? (
        fromToLabel ? (
          fromToLabel === "IN" ? (
            <Typography variant="tableCallIN">IN</Typography>
          ) : fromToLabel === "OUT" ? (
            <Typography variant="tableCallOUT">OUT</Typography>
          ) : (
            <Typography variant="tableCall">SELF</Typography>
          )
        ) : (
          <EastIcon />
        )
      ) : method === "contract-create" ? (
        <Typography variant="tableCall">NEW</Typography>
      ) : method === "contract-call" ? (
        <Typography variant="tableCall">CALL</Typography>
      ) : (
        <Typography variant="tableCall">CALL</Typography>
      )}
    </Box>
  );
}
