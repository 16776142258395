import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getNodeBlock } from "../services/node/node-api.service";
import Spinner from "../components/spinner.component";
import { Box, Container, Stack, Typography } from "@mui/material";

import TimeElapsed from "../components/time-elapsed.component";
import GasPercentageUsed from "../components/gas-percentage-used.component";
import { useSelector } from "react-redux";
import { block } from "../store/block/block.selector";
import BlockDetail from "../components/block/block-information.component";
import BlockTransactions from "../components/block/block-transactions.component";

const BlockPage = () => {
  const blockId = useParams().blockId;
  const [targetBlock, setTargetBlock] = useState(null);
  const [loading, setLoading] = useState(true);
  const blockHeight = useSelector(block);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    if (blockId) {
      getNodeBlock(blockId).then((block) => {
        if (block) {
          setTargetBlock(block);
          setLoading(false);
        }
      });
    }
  }, [blockId]);

  return (
    <>
      {loading ? (
        <Spinner width={"100%"} height={"540px"} iconSize={"40px"} />
      ) : (
        <>
          <BlockDetail targetBlock={targetBlock} blockHeight={blockHeight} />
          {targetBlock.transactions && (
            <BlockTransactions blockId={targetBlock.number} />
          )}
        </>
      )}
    </>
  );
};

export default BlockPage;
