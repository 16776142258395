import configuredContracts from "../../assets/configs/contract.json";
import contractAbis from "../../assets/configs/contract-bis.json";
import { find, first, map, noop, uniq } from "lodash";
import { toChecksumAddress } from "ethereum-checksum-address";
import { store } from "../../store/store";
import { contract } from "../../store/contract/contract.selector";
import { setContractAction } from "../../store/contract/contract.action";
import { EventDecoder } from "../event-decoder/event-decoder";
import { Sep20HelperService } from "../setp-20/sep-20-helper.service";
import { setSep20ContractAction } from "../../store/sep20/sep20.action";

export var eventLogDecoder = undefined;
var sep20Helper = new Sep20HelperService();
var contractArray = [];

export function loadContracts() {
  const contracts = [];
  if (configuredContracts && configuredContracts.length > 0) {
    configuredContracts.forEach((contract) => {
      let type = "custom";
      contract.type === "sep20" ? (type = "sep20") : noop();

      let abi = [];

      contract.abiNames.forEach((name) => {
        abi = abi.concat(find(contractAbis, { type: name })?.abi);
      });

      const newContract = {
        address: toChecksumAddress(contract.address),
        type,
        name: contract.name ?? toChecksumAddress(contract.address),
        abi,
        logo: contract.logo,
      };
      contracts.push(newContract);
    });
  }

  if (contracts.length > 0) {
    eventLogDecoder = new EventDecoder();
    contracts.forEach((contract) => {
      if (contract.abi) eventLogDecoder?.addABI(contract.abi);
    });
    // store.dispatch(setContractAction({ contracts: contracts }));
    contractArray = contracts;
  }
}

export function contractsAddABI(contracts) {
  // if (!contracts.length) return;
  // contracts.forEach((contract) => {
  //   if (contract.abi) eventLogDecoder?.addABI(contract.abi);
  // });
  // store.dispatch(setContractAction({ contracts }));
}

export function addContract(
  address,
  type,
  name,
  abi = undefined,
  data = undefined,
) {
  const existing = find(contractArray, { address: toChecksumAddress(address) });
  if (existing) return existing;

  const newContract = {
    address,
    type,
    name,
    abi,
    logo: false,
  };

  if (type === "sep20") {
    newContract.sep20 = data;
    newContract.abi = find(contractAbis, { type: "sep20" })?.abi;
  }

  if (contractArray.length) {
    contractArray.push(newContract);
  }

  // store.dispatch(setSep20ContractAction({ sep20Contracts: newContract.sep20 }));
  // store.dispatch(setContractAction({ contracts: contractArray }));
  return newContract;
}

export function getContractArray() {
  return contractArray;
}

export async function discover(address) {
  const sep20ContractInfo = await sep20Helper.getSep20ContractInformation(
    toChecksumAddress(address),
    false,
  );

  if (sep20ContractInfo) {
    return Promise.resolve(
      addContract(
        toChecksumAddress(address),
        "sep20",
        sep20ContractInfo.name,
        null,
        sep20ContractInfo,
      ),
    );
  }

  return Promise.resolve(undefined);
}

export async function getContract(address) {
  let contractItem = find(contractArray, {
    address: toChecksumAddress(address),
  });
  if (!contractItem) {
    contractItem = await discover(address);
    return contractItem;
  }
  return contractItem;
}

export function getContractName(address) {
  const contractItem = find(contractArray, {
    address: toChecksumAddress(address),
  });
  return contractItem?.name ?? null;
}

export function getLogsForContract(contractAddress, logs) {
  const eventLogs = [];

  for (let log of logs) {
    const eventLog = {
      contractAddress: toChecksumAddress(log.address),
      log,
      logIndex: log.logIndex,
    };
    const contract = find(contractArray, {
      address: toChecksumAddress(log.address),
    });

    if (contract) {
      eventLog.contractName = contract.name;
    } else {
    }

    if (eventLogDecoder) {
      const decodedLog = eventLogDecoder.decodeLogs([log]);
      eventLog.decodedLog = first(decodedLog);
    }

    eventLogs.push(eventLog);
  }

  return eventLogs;
}

export function getMethodForContract(contractAddress, input) {
  const contractItem = find(contractArray, {
    address: toChecksumAddress(contractAddress),
  });
  let decodedMethod = undefined;
  if (contractItem?.abi) {
    const decoder = new EventDecoder(contractItem.abi);
    decodedMethod = decoder.decodeMethod(input);
  }

  if (!decodedMethod) {
    decodedMethod = { name: input.substr(0, 10) };
  }

  return decodedMethod;
}
