import Web3 from "web3";
const abicoder = require("web3-eth-abi");

export class EventDecoder {
  methodIDs = {};

  constructor(abi) {
    if (abi) {
      this.addABI(abi);
    }
  }

  getMethodIDs() {
    return this.methodIDs;
  }

  decodeMethod(data) {
    const methodID = data.slice(2, 10);
    const abiItem = this.methodIDs[methodID];

    if (abiItem) {
      let decoded = abicoder.decodeParameters(abiItem.inputs, data.slice(10));

      let retData = {
        name: abiItem.name,
        params: [],
      };

      for (let i = 0; i < decoded.__length__; i++) {
        let param = decoded[i];
        let parsedParam = param;
        const isUint = abiItem.inputs[i].type.indexOf("uint") === 0;
        const isInt = abiItem.inputs[i].type.indexOf("int") === 0;
        const isAddress = abiItem.inputs[i].type.indexOf("address") === 0;

        if (isUint || isInt) {
          const isArray = Array.isArray(param);

          if (isArray) {
            parsedParam = param.map((val) => Web3.utils.toBN(val).toString());
          } else {
            parsedParam = Web3.utils.toBN(param).toString();
          }
        }

        if (isAddress) {
          const isArray = Array.isArray(param);

          if (isArray) {
            parsedParam = param.map((param) => param.toLowerCase());
          } else {
            parsedParam = param.toLowerCase();
          }
        }

        if (retData.params) {
          retData.params.push({
            name: abiItem.inputs[i].name,
            value: parsedParam,
            type: abiItem.inputs[i].type,
          });
        }
      }

      return retData;
    }

    return undefined;
  }

  decodeLogs(logs) {
    return logs
      .filter((log) => log.topics.length > 0)
      .map((logItem) => {
        const methodID = logItem.topics[0].slice(2);
        const method = this.methodIDs[methodID];

        if (method) {
          let logData = logItem.data;

          if (
            logData === "0x" &&
            methodID ===
              "ddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
          ) {
            method.inputs[2] = {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            };
          }

          if (
            logData === "0x" &&
            methodID ===
              "8c5be1e5ebec7d5bd14f71427d1e84f3dd0314c0f7b2291e5b200ac8c7c3b925"
          ) {
            method.inputs[2] = {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            };
          }

          let decodedParams = [];
          let dataIndex = 0;
          let topicsIndex = 1;

          let dataTypes = [];
          method.inputs.map((input) => {
            if (!input.indexed) {
              dataTypes.push(input.type);
            }
          });

          let decodedData;

          try {
            decodedData = abicoder.decodeParameters(
              dataTypes,
              logData.slice(2)
            );
          } catch {
            console.log("Error parsing log", method, methodID);
          }

          method.inputs.map((param) => {
            if (decodedData) {
              let decodedP = {
                name: param.name,
                type: param.type,
              };

              if (param.indexed) {
                decodedP.value = logItem.topics[topicsIndex];
                topicsIndex++;
              } else {
                decodedP.value = decodedData[dataIndex];
                dataIndex++;
              }

              if (param.type === "address") {
                decodedP.value = decodedP.value?.toLowerCase();

                if (decodedP.value && decodedP.value.length > 42) {
                  let toRemove = decodedP.value.length - 42;
                  let temp = decodedP.value.split("");
                  temp.splice(2, toRemove);
                  decodedP.value = temp.join("");
                }
              }

              if (
                param.type === "uint256" ||
                param.type === "uint8" ||
                param.type === "int"
              ) {
                if (decodedP.value) {
                  decodedP.value = Web3.utils.toBN(decodedP.value).toString(10);
                }
              }

              decodedParams.push(decodedP);
            }
          });

          return {
            name: method.name,
            events: decodedParams,
            address: logItem.address,
          };
        }

        return null;
      });
  }

  _typeToString(input) {
    if (input.type === "tuple") {
      return "(" + input.components.map(this._typeToString).join(",") + ")";
    }
    return input.type;
  }

  addABI(abiArray) {
    if (Array.isArray(abiArray)) {
      abiArray.map((abi) => {
        if (abi.name) {
          const signature = Web3.utils.sha3(
            abi.name + "(" + abi.inputs.map(this._typeToString).join(",") + ")"
          );

          if (signature) {
            if (abi.type === "event") {
              this.methodIDs[signature.slice(2)] = abi;
            } else {
              this.methodIDs[signature.slice(2, 10)] = abi;
            }
          }
        }
      });
    } else {
      throw new Error("Expected ABI array, got " + typeof abiArray);
    }
  }

  _removeABI(abiArray) {
    if (Array.isArray(abiArray)) {
      abiArray.map((abi) => {
        if (abi.name) {
          const signature = Web3.utils.sha3(
            abi.name +
              "(" +
              abi.inputs
                .map((input) => {
                  return input.type;
                })
                .join(",") +
              ")"
          );

          if (signature) {
            if (abi.type === "event") {
              if (this.methodIDs[signature.slice(2)]) {
                delete this.methodIDs[signature.slice(2)];
              }
            } else {
              if (this.methodIDs[signature.slice(2, 10)]) {
                delete this.methodIDs[signature.slice(2, 10)];
              }
            }
          }
        }
      });
    } else {
      throw new Error("Expected ABI array, got " + typeof abiArray);
    }
  }
}
