import styled from "@emotion/styled";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setBlockAutoRefresh,
  setBlockShowCarousel,
} from "../../store/block/block.action";

const StyledFormControl = styled(FormControl)({
  "& .MuiInputBase-formControl": {
    maxHeight: "40px",
  },
});

const StyledPagination = styled(Pagination)({
  "& .MuiPagination-ul li:last-child button": {
    border: "none",
    fontFamily: "Poppins",
    fontSize: "1rem",
    color: "#828282",
    fontWeight: "500",
    marginLeft: "1rem",
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Next'",
  },
  "& .MuiPagination-ul li:last-child button > svg": {
    display: "none",
  },
  "& .MuiPagination-ul li:first-child button": {
    border: "none",
    fontFamily: "Poppins",
    fontSize: "1rem",
    color: "#828282",
    fontWeight: "500",
    marginRight: "1rem",
  },
  "& .MuiPagination-ul li:first-child button::after": {
    content: "'Prev'",
  },
  "& .MuiPagination-ul li:first-child button > svg": {
    display: "none",
  },

  "& .Mui-selected": {
    backgroundColor: "#CFB06D !important",
    borderColor: "#CFB06D !important",
  },
  "& .MuiPaginationItem-rounded": {
    fontFamily: "Inter",
    fontWeight: 500,
    color: "#4f4f4f",
    borderColor: "#828282",
  },
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#CFB06D",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 16,
    height: 16,
    borderRadius: 16,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const BlockSetting = ({
  itemsPerPage,
  totalRows,
  currentPage,
  handleChangePage,
  handleChangePageSize,
}) => {
  const startIndex = currentPage * itemsPerPage;
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [showCarousel, setShowCarousel] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("autoRefresh")) {
      setAutoRefresh(JSON.parse(localStorage.getItem("autoRefresh")));
      dispatch(
        setBlockAutoRefresh({
          autoRefresh: JSON.parse(localStorage.getItem("autoRefresh")),
        })
      );
    }
    if (localStorage.getItem("showCarousel")) {
      setShowCarousel(JSON.parse(localStorage.getItem("showCarousel")));
      dispatch(
        setBlockShowCarousel({
          showCarousel: JSON.parse(localStorage.getItem("showCarousel")),
        })
      );
    }
    // if(localStorage.getItem("showCarousel")){
    //   setShowCarousel()
    // }
  }, []);

  function handleChangeAutoRefresh(event) {
    dispatch(setBlockAutoRefresh({ autoRefresh: event.target.checked }));
    setAutoRefresh(event.target.checked);
    localStorage.setItem("autoRefresh", event.target.checked);
  }

  function handleChangeShowCarousel(event) {
    dispatch(setBlockShowCarousel({ showCarousel: event.target.checked }));
    localStorage.setItem("showCarousel", event.target.checked);
    setShowCarousel(event.target.checked);
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ paddingBottom: "1.5rem" }}>
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          gap={"1rem"}
        >
          <Box>
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: { xs: "row-reverse", md: "column" },
                gap: "1rem",
              }}
            >
              <FormControlLabel
                onChange={handleChangeAutoRefresh}
                sx={{
                  display: "flex",
                  gap: "1rem",
                  margin: { xs: "unset", md: "" },
                  flexDirection: { xs: "revert", md: "row" },
                }}
                control={<AntSwitch checked={autoRefresh} />}
                label="Auto refresh"
                labelPlacement="start"
              />
              <FormControlLabel
                onChange={handleChangeShowCarousel}
                sx={{
                  display: "flex",
                  gap: "1rem",
                  margin: { xs: "unset", md: "" },
                  flexDirection: { xs: "revert", md: "row" },
                }}
                control={<AntSwitch checked={showCarousel} />}
                label="Show carousel"
                labelPlacement="start"
              />
            </FormGroup>
          </Box>

          <Box
            display={"flex"}
            flexDirection={{ xs: "column-reverse", md: "row" }}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"1rem"}
          >
            <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  color={"#828282"}
                  variant="poppins400"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  Items per page:
                </Typography>
                <StyledFormControl sx={{ m: 1 }}>
                  <Select
                    labelId="rows-per-page-label"
                    id="rows-per-page"
                    value={itemsPerPage}
                    defaultValue={itemsPerPage}
                    onChange={handleChangePageSize}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </StyledFormControl>
              </Box>

              <Typography color={"#828282"} variant="poppins400">
                {startIndex + 1} -{" "}
                {Math.min(startIndex + itemsPerPage, totalRows)} of{" "}
                {totalRows * itemsPerPage}
              </Typography>
            </Box>
            <StyledPagination
              sx={{
                ul: {
                  transform: { xs: "scale(.8)", md: "scale(1)" },
                  flexWrap: { xs: "nowrap", md: "wrap" },
                },
              }}
              variant="outlined"
              shape="rounded"
              count={totalRows}
              page={currentPage}
              onChange={handleChangePage}
            />
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default BlockSetting;
