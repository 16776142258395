import { find } from "lodash";
import { contract } from "../../store/contract/contract.selector";
import { store } from "../../store/store";
import { Sep20HelperService } from "./sep-20-helper.service";
import Web3 from "web3";
import { call } from "../node/node-api.service";
import { setSep20ContractAction } from "../../store/sep20/sep20.action";

const sep20HelperService = new Sep20HelperService();

export class Sep20ResourceService {
  sep20Contracts = [];

  constructor() { }

  // async loadSep20Resource() {
  //   const checkedAddresses = [];
  //   const contractStore = store.getState().contract.contracts;
  //   const contracts = await this.getContracts(contractStore);

  //   for (const contractItem of contracts) {
  //     if (!find(checkedAddresses, contractItem.address)) {
  //       checkedAddresses.push(contractItem.address);
  //       let contractInfo;

  //       if (!contractItem.sep20) {
  //         contractInfo = await sep20HelperService.getSep20ContractInformation(
  //           contractItem.address,
  //           contractItem.logo
  //         );
  //       } else {
  //         contractInfo = contractItem.sep20;
  //       }
  //       if (
  //         contractInfo &&
  //         contractInfo.address !== "0x0000000000000000000000000000000000002711"
  //       ) {
  //         this.sep20Contracts.push(contractInfo);
  //         store.dispatch(
  //           setSep20ContractAction({ sep20Contracts: contractInfo })
  //         );
  //       }
  //     }
  //   }
  // }

  // async loadSep20Resource() {
  //   const checkedAddresses = [];
  //   const contractStore = store.getState().contract.contracts;

  //   const promises = [];

  //   this.getContracts(contractStore).then((contracts) => {
  //     contracts.map(async (contractItem, index) => {
  //       if (!find(checkedAddresses, contractItem.address)) {
  //         checkedAddresses.push(contractItem.address);
  //         if (!contractItem.sep20) {
  //           promises.push(
  //             sep20HelperService.getSep20ContractInformation(
  //               contractItem.address,
  //               contractItem.logo
  //             )
  //           );
  //         } else {
  //           console.log(contractItem.sep20);
  //           promises.push(Promise.resolve(contractItem.sep20));
  //         }
  //       }
  //       if (index == contracts.length - 1) {
  //         Promise.all(promises).then((results) => {
  //           console.log("res ", results);
  //           results.forEach((contract) => {
  //             if (
  //               contract &&
  //               !find(this.sep20Contracts, { address: contract.address })
  //             ) {
  //               if (
  //                 contract.address !==
  //                 "0x0000000000000000000000000000000000002711"
  //               ) {
  //                 this.sep20Contracts.push(contract);
  //               }
  //             }
  //           });
  //         });
  //       }
  //     });
  //   });
  // }

  async getContracts(contractStore) {
    return new Promise((resolve) => {
      if (contractStore.length) {
        resolve(
          contractStore.filter((contractItem) => {
            return contractItem.type === "sep20";
          })
        );
      }
    });
  }

  async getSep20Contract(address) {
    const contracts = store.getState(contract);
    const contractItem = find(contracts, { address: address });
    return Promise.resolve(contractItem);
  }

  async getSep20BalanceForAddress(contractAddress, address) {
    const data =
      Web3.utils.sha3("balanceOf(address)")?.slice(0, 10) +
      "000000000000000000000000" +
      Web3.utils.stripHexPrefix(address);
    return await call(
      {
        to: contractAddress,
        data,
      },
      "uint256"
    );
  }

  async getSep20BalancesForaddress(address, tokenAddresses) {
    return sep20HelperService.getTokenBalances(address, tokenAddresses);
  }
}
