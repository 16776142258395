const BLOCK_ACTION_TYPES = {
  BLOCK_GET_HEIGHT: "block/BLOCK_GET_HEIGHT",
  BLOCK_SET_HEIGHT: "block/BLOCK_SET_HEIGHT",
  BLOCK_GET: "block/BLOCK_GET",
  BLOCK_GET_MULTI: "block/BLOCK_GET_MULTI",
  BLOCK_GET_LATEST: "block/BLOCK_GET_LATEST",
  BLOCK_SET_AUTO_REFRESH: "block/BLOCK_SET_AUTO_REFRESH",
  BLOCK_SET_SHOW_CAROUSEL: "block/BLOCK_SET_SHOW_CAROUSEL",
};

export default BLOCK_ACTION_TYPES;
