import { useEffect, useRef, useState } from "react";
import jazzicon from "jazzicon";
const HashIcon = ({ address, diameter }) => {
  let containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      const icon = jazzicon(diameter, parseInt(address.slice(2, 10), 16));
      if (containerRef.current.hasChildNodes()) {
        containerRef.current.replaceChild(
          icon,
          containerRef.current.firstChild
        );
      } else containerRef.current.appendChild(icon);
    }
  }, [address, diameter]);

  return <div ref={containerRef} />;
};

export default HashIcon;
