import styled from "@emotion/styled";
import {
  TableCell,
  TableRow,
  createTheme,
  tableCellClasses,
} from "@mui/material";

export let theme = createTheme();
theme = createTheme({
  palette: {
    common: {
      black: "#0A0A0A",
    },
    primary: {
      main: "#CFB06D",
    },
    custom: {
      main: "#fff",
    },
    text: {
      primary: "#0A0A0A",
      secondary: "#4F4F4F",
      secondaryLight: "#BDBDBD",
    },
    background: {
      latest: "#f1f1f1",
    },
  },
  typography: {
    largeHeading: {
      fontFamily: "Oxanium",
      fontSize: "3rem",
    },
    mediumHeading: {
      fontFamily: "Oxanium",
      fontSize: "2rem",
    },
    navHeading: {
      fontFamily: "Oxanium",
      fontSize: "1.25rem",
    },
    smallHeading: {
      fontFamily: "Oxanium",
      fontSize: "1rem",
    },
    tableCall: {
      fontFamily: "Oxanium",
      fontSize: ".9rem",
      fontWeight: 600,
      color: "#0A0A0A",
      textTransform: "uppercase",
    },
    tableCallIN: {
      fontFamily: "Oxanium",
      fontSize: ".9rem",
      fontWeight: 600,
      color: "rgba(0, 243, 53, 0.719)",
      textTransform: "uppercase",
    },
    tableCallOUT: {
      fontFamily: "Oxanium",
      fontSize: ".9rem",
      fontWeight: 600,
      color: "rgba(255, 0, 0, 0.719)",
      textTransform: "uppercase",
    },
    subtitle1: {
      fontSize: "1.5rem",
    },
    body2: {
      fontWeight: 300,
    },
    smallPoppins: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 400,
    },
    poppins500: {
      fontFamily: "Poppins",
      fontWeight: 500,
    },
    poppins400: {
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    mulish400: {
      fontFamily: "Mulish",
      fontSize: "1rem",
      fontWeight: 400,
      color: "#0A0A0A",
    },
    mulish700: {
      fontFamily: "Mulish",
      fontSize: "1rem",
      fontWeight: 700,
      color: "#CFB06D",
    },

    bureau: {
      fontFamily: "Bureau",
      fontSize: "12px",
      fontHeight: 400,
      color: "#bdbdbd",
    },
    singlePageTitle: {
      fontFamily: "Roboto Mono",
      fontWeight: 400,
      [theme.breakpoints.up("xs")]: {
        fontSize: "1rem",
        wordBreak: "break-word",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.5rem",
        wordBreak: "unset",
      },
    },
    singlePageTableTitle: {
      fontFamily: "Roboto Mono",
      fontSize: "1rem",
      fontWeight: 400,
    },
    transactionEventCotractAddress: {
      fontFamily: "Roboto Mono",
      fontSize: "1rem",
      fontWeight: 400,
      color: "#25a000",
      "&:hover": {
        textDecoration: "underline",
      },
      [theme.breakpoints.up("xs")]: {
        wordBreak: "break-word",
      },
      [theme.breakpoints.up("md")]: {
        wordBreak: "unset",
      },
    },
    latestTransactions: {
      fontFamily: "Roboto Mono",
      fontSize: "2rem",
      fontWeight: 600,
      color: "#CFB06D",
    },
    singlePageItem: {
      fontFamily: "Heebo",
      fontSize: "1rem",
      fontWeight: 400,
      wordBreak: "break-word",
    },
    singlePageItemTitle: {
      fontFamily: "Heebo",
      fontSize: "1rem",
      fontWeight: 400,
      wordBreak: "break-word",
      color: "#5e5e5e",
    },
    transactionLogParamName: {
      fontFamily: "Heebo",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "blue",
    },
    transactionLogParamType: {
      fontFamily: "Heebo",
      fontSize: "1rem",
      fontWeight: 400,
      color: "red",
    },

    transactionLogParamNameGold: {
      fontFamily: "Heebo",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#CFB06D",
    },
    transactionLogParamTypeGold: {
      fontFamily: "Heebo",
      fontSize: "1rem",
      fontWeight: 400,
      color: "#D1BE8D",
    },

    singlePageItemBold: {
      fontFamily: "Heebo",
      fontSize: "1rem",
      fontWeight: "bold",
      [theme.breakpoints.up("xs")]: {
        whiteSpace: "nowrap",
      },
      [theme.breakpoints.up("md")]: {
        whiteSpace: "unset",
      },
    },
    singlePageItemLink: {
      fontFamily: "Heebo",
      fontWeight: 400,
      color: "darkgoldenrod",
      "&:hover": {
        textDecoration: "underline",
      },
      [theme.breakpoints.up("xs")]: {
        fontSize: ".9rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
  },
});

export const TransactionStyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.primary.main,
    boxShadow: "unset",
    fontSize: 14,
    fontFamily: "Oxanium",
    fontWeight: "600",
    border: "unset",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#F7F7F7",
    border: "unset",
    whiteSpace: "nowrap",
  },
}));

export const TransactionStyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#151515",
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: "unset",
    fontSize: 14,
    fontFamily: "Oxanium",
    fontWeight: "600",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#4F4F4F",
    whiteSpace: "nowrap",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.latest,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: "#ddd",
  },

  "&:nth-of-type(even)": {
    backgroundColor: "#fff",
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "#ddd",
  },
}));

export const DecodedStyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.latest,
  },
}));

export const DecodedStyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: "unset",
    fontSize: 14,
    fontFamily: "Oxanium",
    fontWeight: "600",
    padding: "5px",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#4F4F4F",
    padding: "5px",
    whiteSpace: "nowrap",
  },
}));

export const dateOption = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short",
};

export const tokenListUrl = 'https://tokens.zeniq.net/contractTokens.json'