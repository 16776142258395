import { Box, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const AddressDetail = () => {
  return (
    <Container maxWidth="xl">
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <Typography variant="singlePageTitle">Address</Typography>
        </Box>
      </Stack>
    </Container>
  );
};

export default AddressDetail;
