import {
  Alert,
  Box,
  Container,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TimeElapsed from "../time-elapsed.component";
import GasPercentageUsed from "../gas-percentage-used.component";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import copy from "../../assets/images/copy.png";
import { dateOption } from "../../utils/constants/constants";
import LineSpinner from "../line-spinner/line-spinner.component";
import { useSnackbar } from "../SnackbarProvider";
import { copyToClipboard } from "../../utils/helper/helper";
import TableCellCopy from "../table-cell-copy.component";

const BlockDetail = ({ targetBlock, blockHeight }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleShowSnackbar = (e) => {
    e.preventDefault();
    copyToClipboard(targetBlock.number);
    showSnackbar("The Block number has been copied", "success");
  };

  return (
    <>
      <Box
        padding={{ xs: "2rem 0", md: "2rem" }}
        sx={{ backgroundColor: "background.latest" }}
      >
        <Container maxWidth="xl">
          <Box
            width={"100%"}
            height={"100%"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              width={"100%"}
              gap={"1rem"}
              marginBottom={"2rem"}
            >
              <img
                src={copy}
                alt="copy_icon"
                className="copy_link"
                onClick={handleShowSnackbar}
                width={30}
                height={30}
              />
              <Typography variant="singlePageTitle">
                Block #{targetBlock.number}
              </Typography>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Stack
                display={{ xs: "flex", md: "none" }}
                direction={"row"}
                alignItems={"center"}
                gap={"1rem"}
              >
                <Link
                  to={`/block/${targetBlock.number - 1}`}
                  className={!(targetBlock.number > 1) ? "disable-link" : ""}
                >
                  <WestIcon
                    color={!(targetBlock.number > 1) ? "disabled" : ""}
                  />
                </Link>

                <Link
                  to={`/block/${targetBlock.number + 1}`}
                  className={
                    !(targetBlock.number < blockHeight) ? "disable-link" : ""
                  }
                >
                  <EastIcon
                    color={
                      !(targetBlock.number < blockHeight) ? "disabled" : ""
                    }
                  />
                </Link>
              </Stack>

              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Block Height
              </Typography>

              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"2rem"}
                flex={1}
              >
                <Typography variant="singlePageItemBold">
                  {targetBlock.number}
                </Typography>

                <Stack
                  display={{ xs: "none", md: "flex" }}
                  direction={"row"}
                  alignItems={"center"}
                  gap={"1rem"}
                >
                  <Link
                    to={`/block/${targetBlock.number - 1}`}
                    className={!(targetBlock.number > 1) ? "disable-link" : ""}
                  >
                    <WestIcon
                      color={!(targetBlock.number > 1) ? "disabled" : ""}
                    />
                  </Link>

                  <Link
                    to={`/block/${targetBlock.number + 1}`}
                    className={
                      !(targetBlock.number < blockHeight) ? "disable-link" : ""
                    }
                  >
                    <EastIcon
                      color={
                        !(targetBlock.number < blockHeight) ? "disabled" : ""
                      }
                    />
                  </Link>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Timestamp
              </Typography>

              <Typography variant="singlePageItem" sx={{ flex: 1 }}>
                <TimeElapsed timestamp={targetBlock.timestamp} /> ago (
                {new Date(targetBlock.timestamp * 1000).toLocaleString(
                  "UTC",
                  dateOption
                )}
                )
              </Typography>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Transactions
              </Typography>

              <Typography variant="singlePageItem" sx={{ flex: 1 }}>
                {targetBlock.transactions.length}
              </Typography>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              gap={{ xs: "0.5rem", md: "2rem" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Validator
              </Typography>

              <Link
                to={`/address/${targetBlock.miner}`}
                style={{ flex: "1" }}
                className="address-link"
              >
                <TableCellCopy type={"Validator"} content={targetBlock.miner}>
                  <Typography variant="singlePageItemLink">
                    {targetBlock.miner}
                  </Typography>
                </TableCellCopy>
              </Link>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Size
              </Typography>

              <Typography variant="singlePageItem" sx={{ flex: 1 }}>
                {targetBlock.size} bytes
              </Typography>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Gas Used
              </Typography>

              <Typography variant="singlePageItem" sx={{ flex: 1 }}>
                {targetBlock.gasUsed} (
                <GasPercentageUsed block={targetBlock} />
                %)
              </Typography>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Gas Limit
              </Typography>

              <Typography variant="singlePageItem" sx={{ flex: 1 }}>
                {targetBlock.gasLimit}
              </Typography>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Block Hash
              </Typography>

              <Typography variant="singlePageItem" sx={{ flex: 1 }}>
                <TableCellCopy type={"Hash"} content={targetBlock.hash}>
                  {targetBlock.hash}
                </TableCellCopy>
              </Typography>
            </Stack>

            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                Parent Hash
              </Typography>

              <Typography variant="singlePageItem" sx={{ flex: 1 }}>
                <TableCellCopy
                  type={"Parent Hash"}
                  content={targetBlock.parentHash}
                >
                  {targetBlock.parentHash}
                </TableCellCopy>
              </Typography>
            </Stack>

            <Stack
              paddingTop={"1rem"}
              direction={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              gap={{ xs: "0.5rem", md: "2rem" }}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".1" }}>
                State Root
              </Typography>

              <Typography variant="singlePageItem" sx={{ flex: 1 }}>
                <TableCellCopy
                  type={"State Root"}
                  content={targetBlock.stateRoot}
                >
                  {targetBlock.stateRoot}
                </TableCellCopy>
              </Typography>
            </Stack>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BlockDetail;
