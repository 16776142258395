import {
  setSessionOfflineAction,
  setSessionOnlineAction,
} from "../../store/session/session.action";
import { store } from "../../store/store";
import { Web3Adapter } from "../web3/web3.service";
import { session } from "../../store/session/session.selector";
import { setBlockHeightAction } from "../../store/block/block.action";
import { block } from "../../store/block/block.selector";

const apiConnector = new Web3Adapter();

export async function nodeInit() {
  let sessionState = store.getState(session);
  let apiConfig = sessionState.session.apiConfig;
  if (!apiConfig.apiVersion || !apiConfig.apiEndpoint) {
    store.dispatch(setSessionOfflineAction({ error: "" }));
    return;
  }
  apiConnector
    .init(apiConfig.apiEndpoint)
    .then(() => {
      apiConnector.getBlockHeader().then(async (blockHeight) => {
        if (blockHeight && blockHeight > 0) {
          store.dispatch(setBlockHeightAction({ blockHeight }));
          let chainId = await apiConnector.getChainId();
          store.dispatch(setSessionOnlineAction({ chainId }));
          console.log(
            `[Node-API service]: Successfully retrieved blockheader ${blockHeight}, API is online.`
          );
        } else {
          store.dispatch(setSessionOfflineAction({ error: "" }));
          console.log(
            "[Node-API service]: Error retrieving block 1, API not online."
          );
        }
      });
    })
    .catch((e) => {
      console.error(
        `[Node-API service]: Error Initializing adapter ${apiConfig.apiType}, ${e}`
      );
      store.dispatch(setSessionOfflineAction({ error: "" }));
    });
}

export async function getNodeBlocks(blockIds) {
  if (!apiConnector) return Promise.reject();
  return await apiConnector.getBlocks(blockIds);
}

export async function getNodeBlock(blockId) {
  if (!apiConnector) return Promise.reject();
  return await apiConnector.getBlock(blockId);
}

export async function getNodeBlockHeader() {
  return await apiConnector.getBlockHeader();
}

export async function getNodeLatestTransactions(
  page,
  pageSize,
  searchFromBlock = 0,
  scopeSize = 0
) {
  if (!apiConnector) return Promise.reject();
  return await apiConnector.getLatestTransactions(
    page,
    pageSize,
    searchFromBlock,
    scopeSize
  );
}

export async function callMultiple(items) {
  if (!items.length) return;
  return await apiConnector.callMultiple(items);
}

export async function getTxReceiptsByHashes(hashes) {
  return await apiConnector.getTxReceiptsByHashes(hashes);
}

export function getContractInstant(abi, contractAddress) {
  return apiConnector.getContractInstant(abi, contractAddress);
}

export async function getNodeTxsByAccount(
  address,
  page,
  pageSize,
  type = undefined,
  searchFromBlock = 0,
  scopeSize = 0
) {
  return await apiConnector.getNodeTxsByAccount(
    address,
    page,
    pageSize,
    type,
    searchFromBlock,
    scopeSize
  );
}

export async function getNodeTxByHash(hash) {
  return await apiConnector.getTxByHash(hash);
}

export async function getNodeTxsByBlock(blockId, start, end) {
  return await apiConnector.getTxsByBlock(blockId, start, end);
}

export async function ensAddressLookup(name) {
  return Promise.resolve("");
}

export async function ensNameLookup(address) {
  return Promise.resolve("");
}

export async function getCode(address) {
  return await apiConnector.getCode(address);
}

export async function getAccountBalance(address) {
  return await apiConnector.getAccountBalance(address);
}

export async function getTxCount(address, type = "both") {
  return await apiConnector.getTxCount(address, type);
}

export async function queryLogs(address, data, start, end, limit) {
  return await apiConnector.queryLogs(address, data, start, end, limit);
}

export async function call(transactionConfig, returnType) {
  return await apiConnector.call(transactionConfig, returnType);
}
