import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../utils/constants/constants";
import TruncateAddress from "../truncate-address.component";
import WEI from "../wei.component";
import { getTxByAddress } from "../../services/transaction/transaction-resource.service";
import Spinner from "../spinner.component";
import WarningIcon from "@mui/icons-material/Warning";
import { Link } from "react-router-dom";
import { getAddressName } from "../../services/address/address-resource.service";
import TransactionFromToChip from "../transactions/transaction-from-to-chip.component";
import TableCellCopy from "../table-cell-copy.component";

const AddressTransactions = ({ address }) => {
  const [tableCurrentPage, setTableCurrentPage] = useState(0);
  const [tableCurrentSize, setTableCurrentSize] = useState(10);
  const [transaction, setTransaction] = useState(undefined);
  const [selectedType, setSelectedType] = useState("both");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTxByAddress(
      address,
      selectedType,
      tableCurrentPage + 1,
      tableCurrentSize
    ).then((resp) => {
      setTransaction(resp);
      setLoading(false);
    });
  }, [tableCurrentPage, tableCurrentSize, selectedType, address]);

  const handleSelectChange = (event) => {
    setSelectedType(event.target.value);
    setLoading(true);
  };

  const handleChangePage = (event, newPage) => {
    setTableCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setTableCurrentSize(parseInt(event.target.value, 10));
    setTableCurrentPage(0);
  };

  return (
    <>
      <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
        <FormControl sx={{ m: 1, width: 200 }}>
          <InputLabel id="select_table_type">Table type</InputLabel>
          <Select
            labelId="select_table_type"
            id="select_table"
            value={selectedType}
            label="Table type"
            onChange={handleSelectChange}
          >
            <MenuItem value={"both"}>Both</MenuItem>
            <MenuItem value={"from"}>From</MenuItem>
            <MenuItem value={"to"}>To</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Spinner width={"100%"} height={"200px"} />
      ) : transaction.transactions.length ? (
        <>
          <TableContainer sx={{ marginTop: "2rem" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Hash</StyledTableCell>
                  <StyledTableCell>Method</StyledTableCell>
                  <StyledTableCell>Block</StyledTableCell>
                  <StyledTableCell>From</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>To</StyledTableCell>
                  <StyledTableCell>Value</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {transaction.transactions.map(
                  (singleTransaction) => (
                    (singleTransaction.fromToLabel =
                      address.toLowerCase() ===
                      singleTransaction.data.from.toLowerCase()
                        ? "OUT"
                        : "IN"),
                    (singleTransaction.data.status =
                      singleTransaction.receipt &&
                      singleTransaction.receipt.status === false
                        ? false
                        : true),
                    (singleTransaction.fromName = getAddressName(
                      singleTransaction.data.from
                    )),
                    (singleTransaction.toName = getAddressName(
                      singleTransaction.data.to
                    )),
                    (singleTransaction.contractAddress =
                      singleTransaction.receipt.contractAddress),
                    (
                      <Slide
                        direction="right"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        key={singleTransaction.data?.hash}
                        timeout={500}
                      >
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {
                              <TableCellCopy
                                type={"Hash"}
                                content={singleTransaction.data?.hash}
                              >
                                <Link
                                  to={`/transaction/${singleTransaction.data?.hash}`}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: ".5rem",
                                  }}
                                >
                                  {!singleTransaction.data?.status && (
                                    <div
                                      title={
                                        singleTransaction.data?.statusMessage
                                      }
                                    >
                                      <WarningIcon color="error" />
                                    </div>
                                  )}

                                  <TruncateAddress
                                    address={singleTransaction.data?.hash}
                                  />
                                </Link>
                              </TableCellCopy>
                            }
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Box
                              sx={{
                                backgroundColor: "rgba(218, 165, 32, 0.42)",
                                borderRadius: "3px",
                                paddingX: "1rem",
                                paddingY: ".25rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {singleTransaction.method
                                ? singleTransaction.method?.name
                                : "Transaction"}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <TableCellCopy
                              type={"Block number"}
                              content={singleTransaction.data?.blockNumber}
                            >
                              <Link
                                to={`/block/${singleTransaction.data?.blockNumber}`}
                              >
                                {singleTransaction.data?.blockNumber}
                              </Link>
                            </TableCellCopy>
                          </StyledTableCell>
                          <StyledTableCell>
                            {singleTransaction.fromToLabel === "IN" ? (
                              <TableCellCopy
                                type={"Address"}
                                content={singleTransaction.data?.from}
                              >
                                <Link
                                  to={`/address/${singleTransaction.data?.from}`}
                                >
                                  <TruncateAddress
                                    address={singleTransaction.fromName}
                                  />
                                </Link>
                              </TableCellCopy>
                            ) : (
                              <TableCellCopy
                                type={"Address"}
                                content={singleTransaction.data.from}
                              >
                                <i>
                                  <TruncateAddress
                                    address={singleTransaction.fromName}
                                  />
                                </i>
                              </TableCellCopy>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            <TransactionFromToChip
                              method={singleTransaction.type}
                              fromToLabel={singleTransaction.fromToLabel}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {singleTransaction.type == "contract-create" &&
                            singleTransaction.contractAddress ? (
                              <TableCellCopy
                                type={"Address"}
                                content={singleTransaction.contractAddress}
                              >
                                <Link
                                  to={`/address/${singleTransaction.contractAddress}`}
                                >
                                  <TruncateAddress
                                    address={singleTransaction.contractAddress}
                                  />
                                </Link>
                              </TableCellCopy>
                            ) : singleTransaction.fromToLabel === "OUT" ? (
                              <TableCellCopy
                                type={"Address"}
                                content={singleTransaction.data?.to}
                              >
                                <Link
                                  to={`/address/${singleTransaction.data?.to}`}
                                >
                                  <TruncateAddress
                                    address={singleTransaction.toName}
                                  />
                                </Link>
                              </TableCellCopy>
                            ) : (
                              <TableCellCopy
                                type={"Address"}
                                content={singleTransaction.data.to}
                              >
                                <i>
                                  <TruncateAddress
                                    address={singleTransaction.toName}
                                  />
                                </i>
                              </TableCellCopy>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {
                              <WEI
                                value={singleTransaction.data?.value}
                                arg={"ether"}
                              />
                            }{" "}
                            ZENIQ
                          </StyledTableCell>
                        </StyledTableRow>
                      </Slide>
                    )
                  )
                )}
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    count={transaction.total}
                    rowsPerPage={tableCurrentSize}
                    page={tableCurrentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Typography variant="singlePageTableTitle">
            No transactions found on this address
          </Typography>
        </>
      )}
    </>
  );
};

export default AddressTransactions;
