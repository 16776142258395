import { Box, Typography } from "@mui/material";
import React from "react";

const EmptyResult = ({ props }) => {
  const { innerProps, data } = props;
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      {...innerProps}
      padding={"0.5rem"}
      gap={".5rem"}
    >
      <Typography variant="singlePageItemBold">{data.label}</Typography>
      <Typography variant="singlePageItem" sx={{ fontSize: ".75rem" }}>
        {data.query}
      </Typography>
    </Box>
  );
};

export default EmptyResult;
