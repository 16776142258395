import React from "react";
import Web3 from "web3";
import { Hex } from "web3-utils";
import helpers from "web3-core-helpers";

// import { SBCHSource } from "../../node-api.service";

export const sbchExtensions = {
  property: "sbch",
  methods: [
    {
      name: "queryTxBySrc",
      call: "sbch_queryTxBySrc",
      params: 4,
      inputFormatter: [
        helpers.formatters.inputAddressFormatter,
        null,
        null,
        null,
      ],
      outputFormatter: null,
    },
    {
      name: "queryTxByDst",
      call: "sbch_queryTxByDst",
      params: 4,
      inputFormatter: [
        helpers.formatters.inputAddressFormatter,
        null,
        null,
        null,
      ],
      outputFormatter: null,
    },
    {
      name: "queryTxByAddr",
      call: "sbch_queryTxByAddr",
      params: 4,
      inputFormatter: [
        helpers.formatters.inputAddressFormatter,
        null,
        null,
        null,
      ],
      outputFormatter: null,
    },
    {
      name: "queryLogs",
      call: "sbch_queryLogs",
      params: 5,
      inputFormatter: [
        helpers.formatters.inputAddressFormatter,
        null,
        null,
        null,
        null,
      ],
      outputFormatter: null,
    },
    {
      name: "getTxListByHeight",
      call: "sbch_getTxListByHeight",
      params: 1,
      inputFormatter: [helpers.formatters.inputDefaultBlockNumberFormatter],
      outputFormatter: null,
    },
    {
      name: "getTxListByHeightWithRange",
      call: "sbch_getTxListByHeightWithRange",
      params: 3,
      inputFormatter: [
        helpers.formatters.inputDefaultBlockNumberFormatter,
        null,
        null,
      ],
      outputFormatter: null,
    },
    {
      name: "getAddressCount",
      call: "sbch_getAddressCount",
      params: 2,
      inputFormatter: [null, helpers.formatters.inputAddressFormatter],
      outputFormatter: null,
    },
    {
      name: "getSep20AddressCount",
      call: "sbch_getSep20AddressCount",
      params: 3,
      inputFormatter: [
        null,
        helpers.formatters.inputAddressFormatter,
        helpers.formatters.inputAddressFormatter,
      ],
      outputFormatter: null,
    },
  ],
};
