import { map } from "lodash";
import Web3 from "web3";
import { callMultiple } from "../node/node-api.service";
import { store } from "../../store/store";
import { setSep20ContractAction } from "../../store/sep20/sep20.action";

export const sep20Properties = [
  {
    property: "symbol",
    method: "symbol()",
    type: "string",
  },
  {
    property: "name",
    method: "name()",
    type: "string",
  },
  {
    property: "totalSupply",
    method: "totalSupply()",
    type: "uint256",
  },
  {
    property: "decimals",
    method: "decimals()",
    type: "uint256",
  },
];

export class Sep20HelperService {
  constructor() {}

  async getTokenBalances(address, tokenAddresses) {
    try {
      const callParams = await callMultiple(
        map(tokenAddresses, (tokenAddress) => {
          return {
            transactionConfig: {
              to: tokenAddress,
              data:
                Web3.utils.sha3("balanceOf(address)")?.slice(0, 10) +
                "000000000000000000000000" +
                Web3.utils.stripHexPrefix(address),
            },
            returnType: "uint256",
          };
        })
      );
      return callParams;
    } catch (error) {
      console.log("Sep10 - Line 50");
      console.log(error);
      return null;
    }
  }

  async getSep20ContractInformation(address, logo) {
    try {
      const callParams = await callMultiple(
        map(sep20Properties, (property) => {
          return {
            transactionConfig: {
              to: address,
              data: Web3.utils.sha3(property.method)?.slice(0, 10),
            },
            returnType: property.type,
          };
        })
      );

      if (callParams.length !== sep20Properties.length) {
        return undefined;
      }

      const symbol = callParams[0];
      const name = callParams[1];
      const totalSupply = callParams[2];
      const decimals = callParams[3];
      const sep20Contract = {
        address,
        decimals: parseInt(decimals, 10),
        name,
        symbol,
        totalSupply,
        logo,
      };
      return sep20Contract;
    } catch {
      return undefined;
    }
  }

  async getSep20ContractInformationTemp(address, logo) {
    try {
      const callParams = await callMultiple(
        map(sep20Properties, (property) => {
          return {
            transactionConfig: {
              to: address,
              data: Web3.utils.sha3(property.method)?.slice(0, 10),
            },
            returnType: property.type,
          };
        })
      );
      if (callParams.length !== sep20Properties.length) {
        return undefined;
      }

      const symbol = callParams[0];
      const name = callParams[1];
      const totalSupply = callParams[2];
      const decimals = callParams[3];

      const sep20Contract = {
        address,
        decimals: parseInt(decimals, 10),
        name,
        symbol,
        totalSupply,
        logo,
      };
      return sep20Contract;
    } catch {
      return undefined;
    }
  }
}
