import Web3 from "web3";
import {
  ensNameLookup,
  getAccountBalance,
  getCode,
  getTxCount,
  queryLogs,
} from "../node/node-api.service";
import { getContractName } from "../contract/contract-resource.service";

export async function getAddressInfo(address) {
  const code = await getCode(address);
  const balance = await getAccountBalance(address);
  const ensName = await ensNameLookup(address);
  return {
    address,
    ensName,
    balance,
    type: code !== "0x" ? "contract" : "address",
    code,
    txCount: Web3.utils.hexToNumber(await getTxCount(address, "both")),
  };
}

export function getAddressName(address) {
  let addressLabebel = address;
  addressLabebel = getContractName(address) ?? addressLabebel;

  return addressLabebel;
}

export async function getEventLogs(address, start, end, limit) {
  return queryLogs(
    address,
    [],
    Web3.utils.toHex(start),
    Web3.utils.toHex(end),
    Web3.utils.toHex(limit),
  );
}
