import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TimeElapsed from "../time-elapsed.component";
import GeneralChip from "../general-chip.component";
import { dateOption } from "../../utils/constants/constants";
import WEI from "../wei.component";
import TableCellCopy from "../table-cell-copy.component";

const TransactionDetail = ({ transaction, block, blockHeader }) => {
  const [txFee, setTxFee] = useState(0);
  const [txFeeSatoshi, setTxFeeSatoshi] = useState("");
  const [gasPriceSatoshi, setGasPriceSatoshi] = useState("0");
  const [gasPercentageUsed, setGasPercentageUsed] = useState(0);

  useEffect(() => {
    setTxFee(
      transaction.receipt.gasUsed * parseInt(transaction.data.gasPrice, 10)
    );
    let gasPrice = parseInt(transaction.data.gasPrice, 10);
    setGasPriceSatoshi((gasPrice * 1e-10).toFixed(4));

    if (transaction.receipt?.gasUsed > 0) {
      setGasPercentageUsed(
        (transaction.receipt?.gasUsed / transaction.data.gas) * 100
      );
    }
  }, [transaction]);

  useEffect(() => {
    setTxFeeSatoshi((txFee * 1e-10).toFixed(4));
  }, [txFee]);

  return (
    <Box
      paddingX={{ xs: 0, md: "2rem" }}
      paddingY={"2rem"}
      sx={{ backgroundColor: "background.latest" }}
    >
      <Container maxWidth="xl">
        <Box
          width={"100%"}
          height={"100%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            width={"100%"}
            gap={"1rem"}
            marginBottom={"2rem"}
          >
            <Typography variant="singlePageTitle">
              Transaction
              <TableCellCopy
                type={"Transaction Hash"}
                content={transaction?.data?.hash}
              >
                {transaction?.data?.hash}
              </TableCellCopy>
            </Typography>
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Block
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              <TableCellCopy type={"Block number"} content={block.number}>
                <Link className="address-link" to={`/block/${block.number}`}>
                  <Typography variant="singlePageItemLink">
                    {block.number}
                  </Typography>
                </Link>
                &nbsp; ({blockHeader - block.number} confirmations)
              </TableCellCopy>
            </Typography>
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Status
            </Typography>

            {transaction.receipt?.status ? (
              <GeneralChip
                varient={"singlePageItem"}
                type={"success"}
                value={"SUCCESS"}
              />
            ) : (
              <>
                <GeneralChip
                  varient={"singlePageItem"}
                  type={"error"}
                  value={"FAILED"}
                />
                <Typography variant="singlePageItemBold">
                  {transaction.receipt?.statusStr}
                </Typography>
              </>
            )}
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Timestamp
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              <TimeElapsed timestamp={block.timestamp} /> ago (
              {new Date(block.timestamp * 1000).toLocaleString(
                "UTC",
                dateOption
              )}
              )
            </Typography>
          </Stack>

          {transaction.data?.from && (
            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              gap={{ xs: "0.5rem", md: "2rem" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
                From
              </Typography>

              <Link
                to={`/address/${transaction.data?.from}`}
                style={{ flex: "1" }}
                className="address-link"
              >
                <TableCellCopy
                  type={"Address"}
                  content={transaction.data?.from}
                >
                  <Typography variant="singlePageItemLink">
                    {transaction.data?.from}
                  </Typography>
                </TableCellCopy>
              </Link>
            </Stack>
          )}

          {transaction.data?.to && (
            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              gap={{ xs: "0.5rem", md: "2rem" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
                To
              </Typography>

              <Link
                to={`/address/${transaction.data?.to}`}
                style={{ flex: "1" }}
                className="address-link"
              >
                <TableCellCopy type={"Address"} content={transaction.data?.to}>
                  <Typography variant="singlePageItemLink">
                    {transaction.data?.to}
                  </Typography>
                </TableCellCopy>
              </Link>
            </Stack>
          )}

          {transaction.receipt?.contractAddress && (
            <Stack
              paddingY={"1rem"}
              borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
              direction={{ xs: "column", md: "row" }}
              gap={{ xs: "0.5rem", md: "2rem" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent={"flex-start"}
              width={"100%"}
            >
              <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
                Created Contract
              </Typography>

              <Link
                to={`/address/${transaction.receipt?.contractAddress}`}
                style={{ flex: "1" }}
                className="address-link"
              >
                <TableCellCopy
                  type={"Address"}
                  content={transaction.receipt?.contractAddress}
                >
                  <Typography variant="singlePageItemLink">
                    {transaction.receipt?.contractAddress}
                  </Typography>
                </TableCellCopy>
              </Link>
            </Stack>
          )}

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Value
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              {<WEI value={transaction.data?.value.toString()} arg={"ether"} />}{" "}
              ZENIQ
            </Typography>
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Transaction Fee
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              {<WEI value={txFee.toString()} arg={"ether"} />} ZENIQ (
              {txFeeSatoshi} sats)
            </Typography>
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Gas Price
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              {<WEI value={transaction.data.gasPrice} arg={"ether"} />} ZENIQ (
              {gasPriceSatoshi} sats /{" "}
              {<WEI value={transaction.data.gasPrice} arg={"gwei"} />} gwei)
            </Typography>
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Gas Limit
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              {transaction.data.gas.toLocaleString()}
            </Typography>
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Gas Used by Transaction
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              {transaction.receipt?.gasUsed.toLocaleString()} (
              {gasPercentageUsed.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %)
            </Typography>
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Nonce
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              {transaction.data.nonce}
            </Typography>
          </Stack>

          <Stack
            paddingY={"1rem"}
            borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
            direction={{ xs: "column", md: "row" }}
            gap={{ xs: "0.5rem", md: "2rem" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Typography variant="singlePageItemTitle" sx={{ flex: ".2" }}>
              Position in block
            </Typography>

            <Typography variant="singlePageItem" sx={{ flex: 1 }}>
              {transaction.data.transactionIndex}
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default TransactionDetail;
