import { Typography } from "@mui/material";
import React from "react";

const TruncateAddress = ({ address }) => {
  if (!address) return;
  if (address.startsWith("0x")) {
    if (address.length < 20) return address;
    const truncateAddress = `${address.substr(0, 8)}...${address.substr(-8)}`;
    return truncateAddress;
  }
  return address;
};

export default TruncateAddress;
