import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { block } from "../../store/block/block.selector";
import {
  eventLogDecoder,
  getContract,
} from "../../services/contract/contract-resource.service";
import { EventDecoder } from "../../services/event-decoder/event-decoder";
import { getEventLogs } from "../../services/address/address-resource.service";
import { getTxByHash } from "../../services/transaction/transaction-resource.service";
import Web3 from "web3";
import { find, first, map } from "lodash";
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Spinner from "../spinner.component";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../utils/constants/constants";
import TruncateAddress from "../truncate-address.component";
import { Link } from "react-router-dom";
import GeneralChip from "../general-chip.component";
import DecodedValues from "../transactions/decoded-values.component";
import TableCellCopy from "../table-cell-copy.component";

var inputLogDecoder = undefined;
const TABLECOUNT = 5;
const AddressEventLog = ({ address }) => {
  const blockHeight = useSelector(block);

  const [contract, setContract] = useState(undefined);
  const [lastPage, setLastPage] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [tableCurrentPage, setTableCurrentPage] = useState(0);
  const [tableCurrentSize, setTableCurrentSize] = useState(10);
  const [minBlockHeight, setMinBlockHeight] = useState(0);
  const [retries, setRetries] = useState(0);
  const [tableLength, setTableLength] = useState(0);
  const [tableData, settableData] = useState(undefined);

  useEffect(() => {
    getContractFunction(address).then((tempContract) => {
      if (tempContract && tempContract.abi) {
        inputLogDecoder = new EventDecoder(tempContract.abi);
        setContract(tempContract);
      }
    });

    setTableCurrentPage(0);
    setLastPage(undefined);
    loadLogs(eventLogDecoder);
  }, [address]);

  const getContractFunction = async () => {
    return await getContract(address);
  };

  const loadLogs = async (eventLogDecoderValue) => {
    if (address && blockHeight) {
      setLoading(true);
      const limit = (tableCurrentPage + 1) * tableCurrentSize;
      let response = [];
      try {
        response = await getEventLogs(
          address,
          blockHeight,
          minBlockHeight,
          limit
        );
      } catch (error) {
        if (error.toString().includes("too many candidicate entries")) {
          retries++;
          setMinBlockHeight(Math.ceil(retries * 100000));
          loadLogs();
          return;
        }
      }

      if (lastPage === undefined) {
        if (response && response.length === limit - tableCurrentSize) {
          setTableLength(limit - tableCurrentSize);
          setLastPage(tableCurrentPage - 1);
          previousPage();
        } else if (response.length > 0 && response.length < limit) {
          setTableLength(response.length);
          setLastPage(tableCurrentPage);
        } else {
          setTableLength(response.length + 1);
        }
      }

      const page = response.slice(
        tableCurrentPage * tableCurrentSize,
        (tableCurrentPage + 1) * tableCurrentSize
      );
      const txPromises = map(page, (log) => getTxByHash(log.transactionHash));

      Promise.all(txPromises).then((txs) => {
        settableData(
          map(page, (log) => {
            let rawLog = "";

            log.topics.forEach((topic, index) => {
              rawLog += `[${index}]:     ${topic}`;

              if (index + 1 < log.topics.length) {
                rawLog += `\n`;
              }
              if (log.data && log.data !== "0x") {
                rawLog += `\n[data]:  ${topic}`;
              }
            });

            const tx = find(txs, { data: { hash: log.transactionHash } });
            return mapTableRow(log, rawLog, tx, eventLogDecoderValue);
          })
        );
        setLoading(false);
      });
    }
  };

  const mapTableRow = (row, rawLog, tx = undefined, eventLogDecoderValue) => {
    let log = undefined;
    if (
      row.address.toLowerCase() === address?.toLowerCase() &&
      eventLogDecoderValue
    ) {
      const _log = eventLogDecoderValue.decodeLogs([row]);
      log = first(_log);
    }

    let methodFunction = undefined;

    if (tx && tx.method && tx.method.params) {
      let inner = "";
      tx.method.params.forEach((param, index, params) => {
        inner += ` ${param.name}<${param.type}>`;

        if (params.length > index + 1) {
          inner += `, `;
        }
      });
      methodFunction = `${tx.method.name}( ${inner} )`;
    }

    return {
      blockId: Web3.utils.hexToNumberString(row.blockNumber),
      log: row,
      decodedLog: log,
      method: tx?.data.input.substring(0, 10),
      methodFunction: methodFunction,
      rawLog,
      tx,
    };
  };

  const hasNext = () => {
    return tableData.length === tableCurrentSize;
  };

  const hasPrevious = () => {
    return tableCurrentPage > 0;
  };

  const loadMore = () => {
    if (hasNext()) {
      setTableCurrentSize(tableCurrentSize + TABLECOUNT);
      loadLogs();
    }
  };

  const nextPage = () => {
    setTableCurrentPage((tablePage) => tablePage++);
    loadLogs();
  };

  const previousPage = () => {
    setTableCurrentPage((tablePage) => tablePage--);
    loadLogs();
  };

  const handleChangePage = (event, newPage) => {
    setTableCurrentPage(newPage);
    loadLogs();
  };

  const handleChangeRowsPerPage = (event) => {
    setTableCurrentSize(parseInt(event.target.value, 10));
    setTableCurrentPage(0);
  };

  return (
    <>
      {loading ? (
        <Spinner width={"100%"} height={"200px"} />
      ) : (
        <TableContainer sx={{ marginTop: "2rem" }}>
          <Table sx={{ minWidth: 300 }} aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Transaction
                </StyledTableCell>
                <StyledTableCell sx={{ width: "70%" }}>
                  Event Data
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {tableData.map((singleEvent) => (
                <StyledTableRow
                  key={singleEvent.blockId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <StyledTableCell
                    sx={{ width: "30%" }}
                    component="th"
                    scope="row"
                  >
                    <Stack direction={"column"} gap={"1rem"}>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                      >
                        <Typography>Tx hash</Typography>
                        <TableCellCopy
                          type={"Hash"}
                          content={singleEvent.log.transactionHash}
                        >
                          <Link
                            to={`/transaction/${singleEvent.log.transactionHash}`}
                          >
                            <Typography variant="transactionEventCotractAddress">
                              <TruncateAddress
                                address={singleEvent.log.transactionHash}
                              />
                            </Typography>
                          </Link>
                        </TableCellCopy>
                      </Box>

                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                      >
                        <Typography>Block</Typography>
                        <TableCellCopy
                          type={"Block number"}
                          content={singleEvent.blockId}
                        >
                          <Link to={`/block/${singleEvent.blockId}`}>
                            <Typography variant="transactionEventCotractAddress">
                              <TruncateAddress address={singleEvent.blockId} />
                            </Typography>
                          </Link>
                        </TableCellCopy>
                      </Box>

                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                      >
                        <Typography>Triggered by method</Typography>
                        <GeneralChip
                          varient={"singlePageItem"}
                          type={"success"}
                          value={
                            singleEvent.tx.method?.name ?? singleEvent.method
                          }
                        />
                      </Box>

                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                      >
                        <Typography>Log Index</Typography>
                        <GeneralChip
                          varient={"singlePageItem"}
                          type={"success"}
                          value={singleEvent.log.logIndex}
                        />
                      </Box>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ width: "70%" }}
                    component="th"
                    scope="row"
                  >
                    {singleEvent.decodedLog ? (
                      <Stack direction={"column"} gap={"1rem"}>
                        <Stack direction={{ xs: "column", md: "row" }}>
                          <Typography variant="singlePageItemBold">
                            {`${singleEvent.decodedLog?.name} (`}
                          </Typography>

                          {singleEvent.decodedLog?.events.map(
                            (param, index) => (
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                key={index}
                              >
                                <Typography
                                  sx={{ paddingRight: "0.25rem" }}
                                  variant="transactionLogParamNameGold"
                                >
                                  {param.name}
                                </Typography>
                                <Typography variant="transactionLogParamTypeGold">
                                  {param.type}
                                </Typography>
                                {index !==
                                  singleEvent.decodedLog.events.length - 1 && (
                                  <Typography sx={{ paddingRight: "0.25rem" }}>
                                    ,
                                  </Typography>
                                )}
                                {index ===
                                  singleEvent.decodedLog.events.length - 1 && (
                                  <Typography>{`)`}</Typography>
                                )}
                              </Box>
                            )
                          )}
                        </Stack>
                        <DecodedValues
                          type={"log"}
                          log={singleEvent.log}
                          params={singleEvent.decodedLog.events}
                        />
                      </Stack>
                    ) : (
                      <TextareaAutosize
                        value={singleEvent.rawLog}
                        minRows={1}
                        maxRows={Infinity}
                        disabled
                        style={{
                          width: "-webkit-fill-available",
                          resize: "none",
                        }}
                      />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  count={tableLength}
                  rowsPerPage={5}
                  page={tableCurrentPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AddressEventLog;
