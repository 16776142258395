import Web3 from "web3";
import { Web3Connector } from "./web3.connector";
import { isString, map, noop, orderBy } from "lodash";
import { Mutex } from "async-mutex";
const namehash = require("eth-ens-namehash");

const nameMutex = new Mutex();
const addressMutex = new Mutex();

const ENS_REGISTRY_ADDRESS_MAP = {
  [10000]: "0xCfb86556760d03942EBf1ba88a9870e67D77b627",
  [10001]: "0x32f1FBE59D771bdB7FB247FE97A635f50659202b",
};

export const DEFAULT_QUERY_SIZE = 100000; // max block range per request

export class Web3Adapter {
  apiConnector = undefined;

  init(endpoint) {
    if (!endpoint) return Promise.reject();

    let connectorType;

    endpoint.startsWith("ws://") || endpoint.startsWith("wss://")
      ? (connectorType = "ws")
      : noop();
    endpoint.startsWith("http://") || endpoint.startsWith("https://")
      ? (connectorType = "http")
      : noop();

    try {
      this.apiConnector = new Web3Connector(endpoint, connectorType);
      const web3 = this.apiConnector?.web3;
      web3.eth.getChainId().then((chainId) => {
        const registryAddress = ENS_REGISTRY_ADDRESS_MAP[chainId];
        if (web3.utils.isAddress(registryAddress)) {
          web3.eth.ens.registryAddress = registryAddress;
        }
      });
    } catch (error) {
      console.log("[Node Adapter:Web3] Error connecting to node", error);
      localStorage.removeItem("connection-config");
      return Promise.reject();
    }

    return Promise.resolve(true);
  }

  getChainId() {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getChainId();
  }

  getBlockHeader() {
    if (!this.apiConnector) return Promise.reject();
    return this.apiConnector.getBlockNumber();
  }

  getBlock(blockId) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getBlock(blockId);
  }

  getBlocks(blockIds) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getBlocks(blockIds);
  }

  getTxsByBlock(blockId, start, end) {
    if (!this.apiConnector) return Promise.reject();
    if (start !== undefined && end !== undefined) {
      return this.apiConnector.getTxListByHeightWithRange(blockId, start, end);
    }
    return this.apiConnector.getTxListByHeight(blockId);
  }

  getTxsByBlocks(blockIds) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getTxListByHeights(blockIds);
  }

  getTxByHash(hash) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getTransaction(hash);
  }

  getTxsByHashes(hashes) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getTransactions(hashes);
  }

  async getNodeTxsByAccount(
    address,
    page,
    pageSize,
    type,
    searchFromBlock,
    scopeSize
  ) {
    const totalTxInAddress = Web3.utils.hexToNumber(
      await this.getTxCount(address, type)
    );

    if (!this.apiConnector) return Promise.reject();
    if (!searchFromBlock) searchFromBlock = await this.getBlockHeader();
    if (!scopeSize) scopeSize = 0;

    let scopeBlockId =
      searchFromBlock - scopeSize > 0 ? searchFromBlock - scopeSize : 0;
    let startIndex = (page - 1) * pageSize;
    let endIndex = startIndex + pageSize;

    if (endIndex > totalTxInAddress) {
      endIndex = totalTxInAddress;
    }

    let txFound = [];

    let querySize = DEFAULT_QUERY_SIZE;
    let to = searchFromBlock;
    let from = to - querySize;
    let extendQueryBy = 1;

    if (totalTxInAddress) {
      do {
        if (from < scopeBlockId) from = scopeBlockId;
        if (to < scopeBlockId) to = scopeBlockId;

        let txInThisPage = [];
        let limitReached = false;
        try {
          if (type === "both") {
            txInThisPage = await this.apiConnector.queryTxByAddr(
              address,
              Web3.utils.numberToHex(to),
              Web3.utils.numberToHex(from),
              pageSize * page
            );
          }

          if (type === "from") {
            txInThisPage = await this.apiConnector.queryTxBySrc(
              address,
              Web3.utils.numberToHex(to),
              Web3.utils.numberToHex(from),
              pageSize * page
            );
          }

          if (type === "to") {
            txInThisPage = await this.apiConnector.queryTxByDst(
              address,
              Web3.utils.numberToHex(to),
              Web3.utils.numberToHex(from),
              pageSize * page
            );
          }
        } catch (error) {
          console.log("Web3 service - Line 166");
          if (
            error.message.startsWith(
              "Returned error: too many candidicate entries"
            )
          ) {
            console.log("Limit reached");
            limitReached = true;
            extendQueryBy = 1;

            if (querySize > 1) {
              querySize = Math.floor(querySize / 2);
            }
            from = to - querySize;
          }
        }

        if (txInThisPage.length) {
          txFound = txFound.concat(txInThisPage);
          extendQueryBy = 1;
          querySize = DEFAULT_QUERY_SIZE;
        } else {
          if (extendQueryBy < 10000) extendQueryBy = extendQueryBy * 50;
        }

        if (!limitReached) {
          to = from - 1;
          from = from - querySize * extendQueryBy;
        }
      } while (txFound.length < endIndex && to > scopeBlockId);

      txFound = map(txFound, (tx) => {
        if (isString(tx.blockNumber)) {
          tx.blockNumber = Web3.utils.hexToNumber(tx.blockNumber);
          return tx;
        }
        return tx;
      });

      txFound = orderBy(txFound, ["blockNumber"], ["desc"]);
    }

    const txResults = txFound.slice(startIndex, endIndex);

    return {
      results: txResults,
      page,
      pageSize,
      isEmpty: totalTxInAddress === 0,
      total: totalTxInAddress,
    };
  }
  async getLatestTransactions(page, pageSize, searchFromBlock, scopeSize) {
    if (!this.apiConnector) return Promise.reject();
    if (!searchFromBlock) searchFromBlock = await this.getBlockHeader();
    if (!scopeSize) scopeSize = 0;

    let scope = searchFromBlock - scopeSize;
    if (scope < 0) scope = 0;

    let startIndex = (page - 1) * pageSize;
    let endIndex = startIndex + pageSize;
    let txFound = [];
    let txsFound = [];

    let to = searchFromBlock;
    let from = to - 1;

    let extendedQueryBy = 1;

    do {
      if (from < scope) from = scope;
      if (to < scope) to = scope;

      const ids = [];
      for (let i = from; i <= to; i++) {
        ids.push(i);
      }

      const txs = await this.apiConnector.getTxListByHeights(
        ids.map((id) => Web3.utils.numberToHex(id))
      );

      if (txs.length) {
        txsFound = txsFound.concat(txs);
        extendedQueryBy = 1;
      } else {
        if (extendedQueryBy < 10000) extendedQueryBy = extendedQueryBy * 2;
        if (extendedQueryBy > 10000) extendedQueryBy = 10000;
      }

      to = from - 1;
      from = from - 1 * extendedQueryBy;
    } while (txsFound.length <= endIndex && to > scope);

    txsFound = orderBy(txsFound, ["blockNumber"], ["desc"]);

    txsFound = map(txsFound, (tx) => {
      tx.blockNumber = Web3.utils.hexToNumber(tx.blockNumber);
      return tx;
    });

    const txResults = txsFound.slice(startIndex, endIndex);
    const hashes = map(txResults, (result) => result.transactionHash);
    let transactions = [];
    if (hashes.length) {
      transactions = await this.apiConnector.getTransactions(hashes);
    }

    return {
      results: transactions,
      page,
      pageSize,
      isEmpty: txResults.length === 0,
      total: to < scope ? txFound.length : undefined,
    };
  }

  getTxCount(address, type = "both") {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getTransactionCount(address, type);
  }

  getSep20AddressCount(address, sep20Contract, type) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getSep20TransactionCount(
      address,
      sep20Contract,
      type
    );
  }

  getAccountBalance(address) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getBalance(address);
  }

  getCode(address) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getCode(address);
  }
  getTxReceiptByHash(hash) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getTransactionReceipt(hash);
  }

  getTxReceiptsByHashes(hashes) {
    if (!this.apiConnector) return Promise.reject();

    return this.apiConnector.getTransactionReceipts(hashes);
  }

  getContractInstant(abi, contractAddress) {
    return this.apiConnector.getContractInstant(abi, contractAddress);
  }

  async call(transactionConfig, returnType) {
    if (!this.apiConnector) return Promise.reject();

    let callReturn;

    await this.apiConnector.call(transactionConfig).then((call) => {
      callReturn = this.apiConnector
        ?.getWeb3()
        ?.eth.abi.decodeParameter(returnType, call);
    });

    if (!callReturn) {
      return Promise.reject();
    }

    return Promise.resolve(callReturn);
  }

  async callMultiple(items) {
    if (!this.apiConnector) return Promise.reject();

    let callReturn = [];

    const callResult = await this.apiConnector.callMultiple(
      map(items, (item) => item.transactionConfig)
    );

    for (let i = 0; i < items.length; i++) {
      const returnValue = this.apiConnector
        ?.getWeb3()
        ?.eth.abi.decodeParameter(items[i].returnType, callResult[i]);
      if (returnValue) {
        callReturn.push(returnValue);
      }
    }

    return Promise.resolve(callReturn);
  }

  queryLogs(address, data, start, end, limit) {
    if (!this.apiConnector) return Promise.reject();
    return this.apiConnector.queryLogs(address, data, start, end, limit);
  }

  queryAddressLogs(address) {
    return this.apiConnector?.queryAddressLogs(address);
  }

  async hasMethodFromAbi(address, method, abi) {
    const myMethod = Web3.utils.sha3("symbol()");
    const myMethod3 = Web3.utils.sha3("totalSupply()");

    this.queryLogs(
      address,
      [Web3.utils.keccak256("Transfer(address,address,uint256)")],
      "0x0",
      "latest",
      "0x0"
    ).then((result) => {
      console.log(Web3.utils.stringToHex(result[0].topics[1]));
    });
    return Promise.resolve(false);
  }

  async ensNameLookup(address) {
    if (!this.apiConnector) return Promise.reject();

    const cached = this.nameCache.get(address);
    if (cached !== undefined) {
      return cached;
    }

    const result = await nameMutex.runExclusive(async () => {
      const cached = this.nameCache.get(address);
      if (cached !== undefined) {
        return cached;
      }

      const reverseName =
        (address?.substring(2).toLowerCase() ?? "") + ".addr.reverse";
      const resolver = await this.apiConnector?.web3?.eth.ens.getResolver(
        reverseName
      );
      let name = "";
      try {
        name = await resolver.methods.name(namehash.hash(reverseName)).call();
      } catch {
        console.log("Web3 service - Line 411");
      }
      this.nameCache.set(address, name);
      return name;
    });
    return result;
  }

  async ensAddressLookup(name) {
    if (!this.apiConnector) return Promise.reject();

    const cached = this.addressCache.get(name);
    if (cached !== undefined) {
      return cached;
    }

    const result = await addressMutex.runExclusive(async () => {
      const cached = this.addressCache.get(name);
      if (cached !== undefined) {
        return cached;
      }

      let address = "";
      try {
        address = await this.apiConnector?.web3?.eth.ens.getAddress(name);
      } catch {
        console.log("Web3 service - Line 437");
      }
      this.addressCache.set(name, address);
      return address;
    });
    return result;
  }
}
