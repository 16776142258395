import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Spinner = ({ width, height, color, iconSize }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      width={width}
      height={height}
    >
      <CircularProgress
        sx={{
          color: `${color} !important`,
          width: `${iconSize} !important`,
          height: `${iconSize} !important`,
        }}
      />
    </Box>
  );
};

export default Spinner;
