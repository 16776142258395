import { useParams } from "react-router-dom";
import {
  TransactionDetail,
  TransactionMethod,
  TransactionLogs,
} from "../components/transactions/";
import { useEffect, useState } from "react";
import { getTxByHash } from "../services/transaction/transaction-resource.service";
import { getBlock } from "../services/block/block-resource.service";
import { useSelector } from "react-redux";
import { block } from "../store/block/block.selector";
import Spinner from "../components/spinner.component";
import { Container, TextareaAutosize, Typography } from "@mui/material";

const TransactionPage = () => {
  const transactionParam = useParams().transactionId;
  const blockHeight = useSelector(block);
  const [transaction, setTransaction] = useState({});
  const [transactionBlock, setTransactionBlock] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getTxByHash(transactionParam).then((transactionResp) => {
      setTransaction(transactionResp);
      getBlock(transactionResp.data.blockNumber).then((blockResp) => {
        setTransactionBlock(blockResp);
        setLoading(false);
      });
    });
  }, [transactionParam]);

  return (
    <>
      {loading ? (
        <Spinner width={"100%"} height={"540px"} iconSize={"40px"} />
      ) : (
        <>
          <TransactionDetail
            transaction={transaction}
            block={transactionBlock}
            blockHeader={blockHeight}
          />
          {transaction.method && (
            <TransactionMethod
              name={transaction.method.name}
              params={transaction.method.params}
              input={transaction.data.input}
            />
          )}
          {(transaction.events || transaction.event) && (
            <TransactionLogs events={transaction.events} />
          )}

          {transaction.type === "contract-create" && transaction.data.input && (
            <Container maxWidth="xl" sx={{ mb: "2rem" }}>
              <Typography variant="singlePageTitle">
                Contract creation code
              </Typography>
              <TextareaAutosize
                value={transaction.data.input}
                minRows={1}
                maxRows={20}
                disabled
                style={{ width: "100%", resize: "none", marginTop: "2rem" }}
              />
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default TransactionPage;
