import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  block,
  blockAutoRefresh,
  blockShowCarousel,
} from "../../store/block/block.selector";
import {
  getBlocks,
  getLatestBlocks,
} from "../../services/block/block-resource.service.js";
import LatestBlock from "./latest-block.component";
import "./style.css";
import { first } from "lodash";
import BlockTable from "./block-table.component";
import { Alert, Snackbar } from "@mui/material";

const Block = () => {
  const blockHeight = useSelector(block);
  const autoRefresh = useSelector(blockAutoRefresh);
  const showCarousel = useSelector(blockShowCarousel);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [tableBlocks, setTableBlocks] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [totalRowsTemp, setTotalRowsTemp] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!blocks.length) setLoading(true);
    if ((blockHeight && autoRefresh) || (blockHeight && !blocks.length))
      getLatestBlocks(itemsPerPage, blockHeight).then((resp) => {
        if (resp) {
          setBlocks(resp);
          if (currentPage == 1) {
            setTableBlocks(resp);
            setTotalRowsTemp(
              resp.length && Math.ceil(first(resp).number / itemsPerPage)
            );
          }
          setTotalRows(
            resp.length && Math.ceil(first(resp).number / itemsPerPage)
          );
          setLoading(false);
        }
      });
  }, [blockHeight, itemsPerPage, showCarousel]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleShowSnackbar = () => {
    setOpenSnackbar(true);
  };

  function handleChangePageSize(event) {
    setLoading(true);
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  }

  function handleChangePage(event, page) {
    setCurrentPage(page);
    if (page == 1) {
      getLatestBlocks(itemsPerPage, blockHeight).then((resp) => {
        setTableBlocks(resp);
      });
      return;
    }

    const total = totalRowsTemp * itemsPerPage;
    const startBlock = total - itemsPerPage * (page - 1);

    const ids = [];
    for (let i = startBlock; i > startBlock - itemsPerPage && i > 0; i--) {
      ids.push(i);
    }

    getBlocks(ids).then((resp) => {
      if (resp) {
        setTableBlocks(resp);
      }
    });
  }

  return (
    <>
      {showCarousel && (
        <LatestBlock blocks={blocks} handleShowSnackbar={handleShowSnackbar} />
      )}

      <BlockTable
        itemsPerPage={itemsPerPage}
        totalRows={totalRows}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        handleChangePageSize={handleChangePageSize}
        blocks={tableBlocks}
      />
    </>
  );
};

export default Block;
