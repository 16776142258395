import SESSION_ACTION_TYPES from "./session.types";

const INITIAL_STATE = {
  bootstrapped: false,
  apiOffline: true,
  initialized: false,
  chainId: null,
  error: null,
  apiConfig: {
    apiEndpoint: null,
    apiType: null,
    apiVersion: null,
    network: "",
  },
};

export const sessionReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SESSION_ACTION_TYPES.SESSION_INITIALIZE:
      return {
        ...state,
        apiConfig: {
          apiEndpoint: payload.apiEndpoint,
          apiType: payload.apiType,
          apiVersion: payload.apiVersion,
          network: payload.network,
        },
      };

    case SESSION_ACTION_TYPES.SESSION_SET_OFFLINE:
      return {
        ...state,
        bootstrapped: true,
        apiOffline: true,
        initialized: false,
        chainId: null,
        error: payload.error,
      };

    case SESSION_ACTION_TYPES.SESSION_SET_ONLINE:
      return {
        ...state,
        bootstrapped: true,
        apiOffline: false,
        initialized: true,
        chainId: payload.chainId,
      };

    case SESSION_ACTION_TYPES.SESSION_RESET:
      return {
        INITIAL_STATE,
      };
    default:
      return state;
  }
};
