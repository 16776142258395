import React, { useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { SnackbarContext } from "./SnackbarProvider";

const GlobalSnackbar = () => {
  const { snackbarData, handleClose } = useContext(SnackbarContext);

  return (
    <Snackbar
      open={snackbarData.open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={3}
        variant="filled"
        onClose={handleClose}
        severity={snackbarData.severity}
      >
        {snackbarData.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
