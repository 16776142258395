import BigNumber from "bignumber.js";

export function convertValue(data, decimals) {
  const convertedValue = new BigNumber(data)
    .dividedBy(new BigNumber(`1e${decimals}`))
    .toFixed(decimals);
  return convertedValue.toString();
}

export function convertTopicAddress(data) {
  return "0x" + data.slice(data.length - 40, data.length);
}

export function getGasPercentageUsed(block) {
  return ((block.gasUsed / block.gasLimit) * 100).toFixed(5);
}

export function numberWithCommas(x) {
  return x
    .toString()
    .split("")
    .reverse()
    .join("")
    .replace(/(\d{3}(?!.*\.|$))/g, "$1,")
    .split("")
    .reverse()
    .join("");
}
