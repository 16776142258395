import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WEI from "../wei.component";
import TruncateAddress from "../truncate-address.component";

const TxResult = ({ props }) => {
  const { innerProps, data } = props;
  return (
    <Link to={data.url}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        {...innerProps}
        padding={"0.5rem"}
        gap={".5rem"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="singlePageItemBold">
            Transaction{" "}
            <Typography variant="singlePageItem">{data.txType}</Typography>{" "}
          </Typography>
          <Typography variant="singlePageItemBold">
            {<WEI value={data.balance} arg={"ether"} />} ZENIQ
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="singlePageItem" sx={{ fontSize: ".75rem" }}>
            {<TruncateAddress address={data.hashCode} />} in block{" "}
            {data.blockNumber}
          </Typography>
          <Typography variant="singlePageItem" sx={{ fontSize: ".75rem" }}>
            {data.timestamp}
          </Typography>
        </Stack>
      </Box>
    </Link>
  );
};

export default TxResult;
