import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DecodedStyledTableCell,
  DecodedStyledTableRow,
} from "../../utils/constants/constants";
import { isArray } from "lodash";
import { getAddressName } from "../../services/address/address-resource.service";
import { Link } from "react-router-dom";
import ChecksumAddress from "../checksum-address.component";
import TableCellCopy from "../table-cell-copy.component";

const DecodedValues = ({ params, log, type }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (params) {
      setTableData([]);
      params.forEach((param, index) => {
        const row = {
          index,
          name: param.name,
          type: param.type,
          data: isArray(param.value) ? param.value : new Array(param.value),
          extraData: [],
        };

        row.data.forEach((value) => {
          if (
            param.type?.startsWith("address") &&
            value !== getAddressName(value)
          ) {
            row.extraData?.push({
              name: getAddressName(value),
              value,
            });
          }
        });
        setTableData((current) => [...current, row]);
      });
    }
  }, [params]);

  return (
    <TableContainer
      sx={{
        boxShadow:
          "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
      }}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <DecodedStyledTableCell>#</DecodedStyledTableCell>
            <DecodedStyledTableCell>Name</DecodedStyledTableCell>
            <DecodedStyledTableCell>Type</DecodedStyledTableCell>
            <DecodedStyledTableCell>Data</DecodedStyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((element, index) => (
            <DecodedStyledTableRow key={index}>
              <DecodedStyledTableCell>{element.index}</DecodedStyledTableCell>
              <DecodedStyledTableCell>{element.name}</DecodedStyledTableCell>
              <DecodedStyledTableCell>{element.type}</DecodedStyledTableCell>
              <DecodedStyledTableCell>
                {element.data.map((item, index) =>
                  element.type == "address" || element.type == "address[]" ? (
                    item !== "0x0000000000000000000000000000000000000000" ? (
                      <TableCellCopy type={"Address"} content={item}>
                        <Link
                          key={index}
                          className="address-link"
                          to={`/address/${item}`}
                        >
                          <Typography variant="singlePageItemLink">
                            <ChecksumAddress value={item} />
                          </Typography>
                        </Link>
                      </TableCellCopy>
                    ) : (
                      <Typography key={index}>{item}</Typography>
                    )
                  ) : (
                    <Typography key={index}>{item}</Typography>
                  )
                )}
              </DecodedStyledTableCell>
            </DecodedStyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DecodedValues;
