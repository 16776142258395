import { createAction } from "../../utils/reducer/reducer.utils";
import BLOCK_ACTION_TYPES from "./block.types";

export const setBlockHeightAction = (block) =>
  createAction(BLOCK_ACTION_TYPES.BLOCK_SET_HEIGHT, block);

export const setBlockAutoRefresh = (autoRefresh) =>
  createAction(BLOCK_ACTION_TYPES.BLOCK_SET_AUTO_REFRESH, autoRefresh);

export const setBlockShowCarousel = (showCarousel) =>
  createAction(BLOCK_ACTION_TYPES.BLOCK_SET_SHOW_CAROUSEL, showCarousel);
