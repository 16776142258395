import React from "react";
import Navbar from "./navbar.component";
import Footer from "./footer.component";
import Spinner from "./spinner.component";

const Layout = ({ loading, children }) => {
  return (
    <>
      <Navbar />

      {loading ? <Spinner /> : children}
      <Footer />
    </>
  );
};

export default Layout;
