import BLOCK_ACTION_TYPES from "./block.types";

const INITIAL_STATE = {
  showCarousel: true,
  autoRefresh: true,
};

export const blockReducer = (state = INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case BLOCK_ACTION_TYPES.BLOCK_SET_HEIGHT:
      return {
        ...state,
        blockHeight: payload.blockHeight,
      };

    case BLOCK_ACTION_TYPES.BLOCK_SET_SHOW_CAROUSEL:
      return {
        ...state,
        showCarousel: payload.showCarousel,
      };

    case BLOCK_ACTION_TYPES.BLOCK_SET_AUTO_REFRESH:
      return {
        ...state,
        autoRefresh: payload.autoRefresh,
      };

    default:
      return state;
  }
};
