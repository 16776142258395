import { useEffect, useState } from "react";
import { initialSession } from "./services/session/session.service";
import {
  initilizeSessionAction,
  setSessionOfflineAction,
} from "./store/session/session.action";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { HomePage, AddressPage, TransactionPage, BlockPage } from "./pages";
import Layout from "./components/layout.component";
import { nodeInit, getNodeBlockHeader } from "./services/node/node-api.service";
import { setBlockHeightAction } from "./store/block/block.action";
import { theme } from "./utils/constants/constants";
import ConnectToNodeDialog from "./components/connect-to-node-dialog.component";
import { session } from "./store/session/session.selector";
import { loadContracts } from "./services/contract/contract-resource.service";
import { Sep20ResourceService } from "./services/setp-20/sep-20-resource.service";
import SnackbarProvider from "./components/SnackbarProvider";
import NotFound from "./components/not-found.component";

let showErrorDialog = false;

export function setShowErrorDialog() {
  showErrorDialog = !showErrorDialog;
}

function App() {
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const sessionState = useSelector(session);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showErrorDialog) {
      setShowDialog(true);
    }
  }, [showErrorDialog]);

  const initial = () => {
    initialSession()
      .then((loadedConfig) => {
        dispatch(initilizeSessionAction(loadedConfig));
        nodeInit()
          .then(() => {
            loadContracts();
            setLoading(false);
          })
          .catch((err) => {
            dispatch(setSessionOfflineAction({ error: err }));
          });

        const interval = setInterval(() => {
          getNodeBlockHeader()
            .then((blockHeight) => {
              dispatch(setBlockHeightAction({ blockHeight }));
            })
            .catch((err) => {
              dispatch(setSessionOfflineAction({ error: err }));
            });
        }, 3000);
        return () => clearInterval(interval);
      })
      .catch((err) => {
        dispatch(setSessionOfflineAction({ error: err }));
      });
  };

  useEffect(() => {
    initial();
  }, []);

  useEffect(() => {
    initial();
  }, [sessionState.apiOffline]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {!loading && (
          <BrowserRouter>
            <Grid container>
              <Grid item xs={12}>
                <Layout loading={loading}>
                  <SnackbarProvider>
                    <Routes>
                      <Route path="/" exact element={<HomePage />} />

                      <Route
                        path="/transaction/:transactionId"
                        element={<TransactionPage />}
                      />
                      <Route
                        path="/tx/:transactionId"
                        element={<TransactionPage />}
                      />
                      <Route
                        path="/address/:addressId"
                        element={<AddressPage />}
                      />
                      <Route
                        path="/token/:addressId"
                        element={<AddressPage />}
                      />
                      <Route path="/block/:blockId" element={<BlockPage />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </SnackbarProvider>
                </Layout>
              </Grid>
            </Grid>
          </BrowserRouter>
        )}
      </ThemeProvider>
    </>
  );
}

export default App;
