import React from "react";
import { Block, Transaction } from "../components";
const HomePage = () => {
  return (
    <>
      <div>
        <Block />
        <Transaction />
      </div>
    </>
  );
};

export default HomePage;
