import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import TruncateAddress from "../truncate-address.component";
import copy from "../../assets/images/copy.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TimeElapsed from "../time-elapsed.component";
import GasPercentageUsed from "../gas-percentage-used.component";
import { useSnackbar } from "../SnackbarProvider";
import { copyToClipboard } from "../../utils/helper/helper";
const LatestBlockItem = ({ block }) => {
  const { showSnackbar } = useSnackbar();

  const handleShowSnackbar = (e, blockNumber) => {
    e.preventDefault();
    copyToClipboard(blockNumber);
    showSnackbar("The Block number has been copied", "success");
  };
  return (
    <Link className="block_item" to={`/block/${block.number}`}>
      <Stack
        boxShadow={"0px 15px 35px rgba(0, 0, 0, 0.05)"}
        borderRadius={"3px"}
      >
        <Box
          paddingTop={"2rem"}
          paddingX={"1rem"}
          paddingBottom={"1rem"}
          backgroundColor="#fff"
          display={"flex"}
          flexDirection={"column"}
          gap={"1rem"}
        >
          {/* head */}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} alignItems={"center"} gap={".5rem"}>
              <img
                src={copy}
                alt=""
                className="copy_link"
                onClick={(e) => handleShowSnackbar(e, block.number)}
              />
              <Typography variant="smallHeading">{block.number}</Typography>
            </Box>

            <Typography variant="smallPoppins">
              <TimeElapsed timestamp={block.timestamp} /> ago
            </Typography>
          </Box>

          {/* hash */}
          <Box
            backgroundColor="primary.main"
            padding={"1rem"}
            display={"flex"}
            alignItems={"flex-start"}
            flexDirection={"column"}
            gap={".5rem"}
          >
            <Typography variant="poppins500">Block hash</Typography>
            <Typography variant="poppins400">
              <TruncateAddress address={block.hash} />
            </Typography>
          </Box>

          {/* miner */}
          <Box
            backgroundColor="hsla(43,42%,69%,.8)"
            padding={"1rem"}
            display={"flex"}
            alignItems={"flex-start"}
            flexDirection={"column"}
            gap={".5rem"}
          >
            <Typography variant="poppins500">Miner hash</Typography>
            <Typography variant="poppins400">
              <TruncateAddress address={block.miner} />
            </Typography>
          </Box>

          {/* footer */}

          {block.transactions.length ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="poppins500">
                {block.transactions.length} txn
              </Typography>
              <Typography variant="poppins500">
                ({<GasPercentageUsed block={block} />} %)
              </Typography>
            </Box>
          ) : (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="poppins500">Empty Block</Typography>
              <ChevronRightIcon />
            </Box>
          )}
        </Box>
      </Stack>
    </Link>
  );
};

export default LatestBlockItem;
