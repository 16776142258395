import React, { useEffect, useState } from "react";
import { getLatestTransactions } from "../services/transaction/transaction-resource.service";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import {
  TransactionStyledTableCell,
  TransactionStyledTableRow,
} from "../utils/constants/constants";
import TruncateAddress from "./truncate-address.component";
import WEI from "./wei.component";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WarningIcon from "@mui/icons-material/Warning";
import Spinner from "./spinner.component";
import { Link } from "react-router-dom";
import TransactionFromToChip from "./transactions/transaction-from-to-chip.component";
import TableCellCopy from "./table-cell-copy.component";

const Transaction = () => {
  const TABLECOUNT = 10;
  const REFRESH_INTERVAL = 60000;
  const BLOCK_SCOPE = 1000;

  const [transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadForMore, setLoadForMore] = useState(false);
  const [isCallMore, setIsCallMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [tableCurrentSize, setTableCurrentSize] = useState(TABLECOUNT);

  useEffect(() => {
    loadLatestTransactions();

    setInterval(() => {
      if (!loadForMore) {
        setRefreshing(true);
        loadLatestTransactions();
      }
    }, REFRESH_INTERVAL);
  }, []);

  useEffect(() => {
    loadLatestTransactions();
  }, [tableCurrentPage, tableCurrentSize]);

  function loadLatestTransactions() {
    getLatestTransactions(
      tableCurrentPage,
      tableCurrentSize,
      undefined,
      BLOCK_SCOPE
    ).then((resp) => {
      setTransaction(resp);
      setLoading(false);
      setRefreshing(false);
      setLoadForMore(false);
    });
  }

  function handleMore() {
    if (hasMore()) {
      setLoadForMore(true);
      setIsCallMore(true);
      setTableCurrentPage(tableCurrentPage + 1);
      setTableCurrentSize(tableCurrentSize + TABLECOUNT);
    }
  }

  const handleReset = () => {
    setLoadForMore(true);
    setTableCurrentPage(1);
    setIsCallMore(false);
    setTableCurrentSize(TABLECOUNT);
  };

  const hasMore = () => {
    return transaction.transactions.length === tableCurrentSize;
  };

  return (
    <Box sx={{ backgroundColor: "common.black" }}>
      <Container maxWidth="xl" sx={{ paddingY: "3rem" }}>
        <Typography variant="latestTransactions">
          Latest transactions
        </Typography>

        {loading ? (
          <Box marginY={"2rem"}>
            <Spinner color={"#fff"} />
          </Box>
        ) : (
          <>
            <TableContainer sx={{ marginTop: "2rem" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TransactionStyledTableRow>
                    <TransactionStyledTableCell>
                      Hash
                    </TransactionStyledTableCell>
                    <TransactionStyledTableCell>
                      Block
                    </TransactionStyledTableCell>
                    <TransactionStyledTableCell>
                      From
                    </TransactionStyledTableCell>
                    <TransactionStyledTableCell></TransactionStyledTableCell>
                    <TransactionStyledTableCell>To</TransactionStyledTableCell>
                    <TransactionStyledTableCell>
                      Value
                    </TransactionStyledTableCell>
                  </TransactionStyledTableRow>
                </TableHead>
                <TableBody>
                  {transaction.transactions.map((singleTransaction) => (
                    <TransactionStyledTableRow
                      key={singleTransaction.data?.hash}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TransactionStyledTableCell component="th" scope="row">
                        {
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"0.5rem"}
                          >
                            {singleTransaction.receipt.status ? (
                              ""
                            ) : (
                              <WarningIcon color="error" />
                            )}

                            {
                              <TableCellCopy
                                type={"Hash"}
                                content={singleTransaction.data?.hash}
                                color={"white"}
                              >
                                <Link
                                  to={`/transaction/${singleTransaction.data?.hash}`}
                                >
                                  <TruncateAddress
                                    address={singleTransaction.data?.hash}
                                  />
                                </Link>
                              </TableCellCopy>
                            }
                          </Box>
                        }
                      </TransactionStyledTableCell>
                      <TransactionStyledTableCell component="th" scope="row">
                        <TableCellCopy
                          type={"Block number"}
                          content={singleTransaction.data?.blockNumber}
                          color={"white"}
                        >
                          <Link
                            to={`/block/${singleTransaction.data?.blockNumber}`}
                          >
                            {singleTransaction.data?.blockNumber}
                          </Link>
                        </TableCellCopy>
                      </TransactionStyledTableCell>
                      <TransactionStyledTableCell>
                        <TableCellCopy
                          type={"Address"}
                          content={singleTransaction.data?.from}
                          color={"white"}
                        >
                          <Link to={`/address/${singleTransaction.data?.from}`}>
                            {
                              <TruncateAddress
                                address={singleTransaction.data?.from}
                              />
                            }
                          </Link>
                        </TableCellCopy>
                      </TransactionStyledTableCell>
                      <TransactionStyledTableCell>
                        <TransactionFromToChip
                          method={singleTransaction.type}
                        />
                      </TransactionStyledTableCell>
                      <TransactionStyledTableCell>
                        <TableCellCopy
                          type={"Address"}
                          content={singleTransaction.data?.to}
                          color={"white"}
                        >
                          <Link to={`/address/${singleTransaction.data?.to}`}>
                            {
                              <TruncateAddress
                                address={singleTransaction.data?.to}
                              />
                            }
                          </Link>
                        </TableCellCopy>
                      </TransactionStyledTableCell>
                      <TransactionStyledTableCell>
                        {
                          <WEI
                            value={singleTransaction.data?.value}
                            arg={"ether"}
                          />
                        }{" "}
                        ZENIQ
                      </TransactionStyledTableCell>
                    </TransactionStyledTableRow>
                  ))}
                  {/* <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  count={transaction.total}
                  rowsPerPage={tableCurrentSize}
                  page={tableCurrentPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              marginTop={"3rem"}
              gap={"1rem"}
            >
              {loadForMore ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  gap={"1rem"}
                  alignItems={"center"}
                >
                  <Spinner
                    width={"20px"}
                    height={"20px"}
                    color={"#CFB06D"}
                    iconSize={"20px"}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      letterSpacing: "0.02857em",
                      textTransform: "uppercase",
                      color: "#CFB06D",
                    }}
                  >
                    Loading...
                  </Typography>
                </Box>
              ) : (
                <>
                  <Button
                    onClick={handleMore}
                    variant="text"
                    endIcon={
                      !loadForMore && !refreshing && <KeyboardArrowDownIcon />
                    }
                  >
                    {refreshing ? "Refreshing ..." : "More"}
                  </Button>

                  {isCallMore && (
                    <Button
                      onClick={handleReset}
                      variant="text"
                      endIcon={<KeyboardArrowUpIcon />}
                    >
                      Less
                    </Button>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default Transaction;
