import React, { useEffect, useState } from "react";
import { numberWithCommas } from "../services/util/util-helper.service";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import HashIcon from "./hash-icon.component";
import sep20Abis from "../assets/configs/sep20-abi.json";
import Web3 from "web3";
import { getContractInstant } from "../services/node/node-api.service";
import { tokenListUrl } from "../utils/constants/constants";

const AddressTokensSep20List = ({ address }) => {
  const [sep20BalanceList, setSep20BalanceList] = useState([]);
  const [activeSep20Contract, setActiveSep20Contract] = useState(undefined);
  const [activeBalance, setActiveBalance] = useState("" | undefined);
  const [contractTokens, setContractTokens] = useState([])

  useEffect(() => {
      fetch(tokenListUrl)
          .then(res => res.json())
          .then(data => {
              setContractTokens(data)
          })
          .catch(err => { throw err });
  }, [])

  useEffect(() => {
    initialize();
  }, [contractTokens]);

  const initialize = async () => {
    setActiveBalance(undefined);
    setActiveSep20Contract(undefined);
    setSep20BalanceList([]);

    const _address = address;
    let sep20BalanceListArray = [];

    for (let i = 0; i < contractTokens.length; i++) {
      const contract = contractTokens[i];
      const contractAddress = contract.address;

      const contractInstance = getContractInstant(
        sep20Abis.abi,
        contractAddress
      );

      contractInstance.methods
        .balanceOf(address)
        .call()
        .then(async (balance) => {
          const balanceToken = Web3.utils.fromWei(balance, "ether");

          const contractSymbol = contract.symbol;
          let splitBalance = balanceToken.split(".");
          let logoResponse = await fetch(
            `https://price.zeniq.services/v2/info/image/${contract.symbol.toLowerCase()}`
          );
          let logo = {};
          if (logoResponse.ok) {
            logo = await logoResponse.json();
          } else {
            logo = null;
          }
          if (balanceToken > 0)
            setSep20BalanceList((prev) =>
              [
                ...prev,
                {
                  contract,
                  balanceToken,
                  logoItem: logo,
                  balanceWhole: numberWithCommas(splitBalance[0]),
                  balanceFraction: splitBalance[1]
                    ? splitBalance[1]
                    : undefined,
                },
              ].sort((a, b) => b.balanceToken - a.balanceToken)
            );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <>
      {" "}
      {sep20BalanceList.length > 0 && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          boxShadow={
            "0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)"
          }
          padding={"1rem"}
          gap={"2rem"}
        >
          <Stack direction={"row"}>
            <Typography>Token balances</Typography>
          </Stack>
          <Stack direction={"column"} gap={"1rem"} alignItems={"flex-start"}>
            {sep20BalanceList.map(
              (balance) =>
                balance.balanceToken > 0 && (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={"1rem"}
                    key={balance.contract.address}
                    width={"100%"}
                  >
                    <Box
                      display={"flex"}
                      direction={"row"}
                      alignItems={"center"}
                      gap={".5rem"}
                      flex={0.5}
                    >
                      {balance.logoItem?.thumb ? (
                        <img
                          src={balance.logoItem.thumb}
                          style={{ borderRadius: "50%" }}
                          width={30}
                          height={30}
                        />
                      ) : balance.contract.symbol == "SIDI" ? (
                        <img
                          src={`https://sido-immobilien.at/wp-content/uploads/2021/10/Sido_Logo_Coin-1536x1536.png`}
                          style={{ borderRadius: "50%" }}
                          width={30}
                          height={30}
                        />
                      ) : (
                        <HashIcon
                          address={balance.contract.address}
                          diameter={30}
                        />
                      )}

                      <Link
                        className="address-link"
                        to={`/address/${balance.contract.address}`}
                      >
                        <Typography variant="singlePageItemLink">
                          {balance.contract.symbol}
                        </Typography>
                      </Link>
                    </Box>

                    <Typography
                      sx={{ flex: 1, fontSize: { xs: ".75rem", md: "" } }}
                    >
                      <Typography variant="singlePageItemBold">
                        {balance.balanceWhole}
                      </Typography>

                      {balance.balanceFraction && `.${balance.balanceFraction}`}
                    </Typography>
                  </Stack>
                )
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default AddressTokensSep20List;
