import SESSION_ACTION_TYPES from "./session.types";
import { createAction } from "../../utils/reducer/reducer.utils";

export const initilizeSessionAction = (session) =>
  createAction(SESSION_ACTION_TYPES.SESSION_INITIALIZE, session);

export const setSessionOnlineAction = (session) =>
  createAction(SESSION_ACTION_TYPES.SESSION_SET_ONLINE, session);

export const setSessionOfflineAction = (session) =>
  createAction(SESSION_ACTION_TYPES.SESSION_SET_OFFLINE, session);
