import {
  Box,
  Container,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import TimeElapsed from "../time-elapsed.component";
import TruncateAddress from "../truncate-address.component";
import GasPercentageUsed from "../gas-percentage-used.component";
import Spinner from "../spinner.component";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../utils/constants/constants";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import TableCellCopy from "../table-cell-copy.component";

const convertBytesToPercentage = (bytes) => {
  const maxBytes = 12 * 1024 * 1024; // 12 megabytes in bytes
  const percentage = (bytes / maxBytes) * 100;
  return percentage.toFixed(4);
};


const LatestBlockTable = ({ blocks }) => {
  const [loading, setLoading] = useState(true);

  const renderedBlocks = blocks.map((block, index) => {
    return (
      <Slide
        direction="right"
        in={true}
        mountOnEnter
        unmountOnExit
        key={block.number}
        timeout={500}
      >
        <StyledTableRow
          component={Link}
          to={`/block/${block.number}`}
          className="table-hover"
        >
          <StyledTableCell align="left">
            <TableCellCopy type={"Block number"} content={block.number}>
              <Link to={`/block/${block.number}`}>
                <b>{block.number}</b>
              </Link>
            </TableCellCopy>
          </StyledTableCell>

          <StyledTableCell align="left">
            <TimeElapsed timestamp={block.timestamp} />
          </StyledTableCell>

          <StyledTableCell align="left" title={block.hash}>
            <TableCellCopy type={"Block hash"} content={block.hash}>
              <TruncateAddress address={block.hash} />
            </TableCellCopy>
          </StyledTableCell>
          <StyledTableCell align="left" title={block.miner}>
            <TableCellCopy type={"Block validator"} content={block.miner}>
              <Link to={`/address/${block.miner}`}>
                <TruncateAddress address={block.miner} />
              </Link>
            </TableCellCopy>
          </StyledTableCell>
          <StyledTableCell align="left">
            {block.transactions.length}
          </StyledTableCell>
          <StyledTableCell align="left">
            {convertBytesToPercentage(block.size)} %
          </StyledTableCell>
          <StyledTableCell align="left">{block.size}</StyledTableCell>
        </StyledTableRow>
      </Slide>
      // </CSSTransition>
    );
  });

  useEffect(() => {
    if (renderedBlocks.length == blocks.length) setLoading(false);
  }, [renderedBlocks]);

  return (
    <Box paddingY={"2rem"}>
      <Container maxWidth="xl">
        {loading ? (
          <Box
            height={100}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner />
          </Box>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ boxShadow: "unset" }}>
                  <StyledTableCell align="left">Block</StyledTableCell>
                  <StyledTableCell align="left">Age</StyledTableCell>
                  <StyledTableCell align="left">Hash</StyledTableCell>
                  <StyledTableCell align="left">Validator</StyledTableCell>
                  <StyledTableCell align="left">Transactions</StyledTableCell>
                  <StyledTableCell align="left">Size (%)</StyledTableCell>
                  <StyledTableCell align="left">Size (bytes)</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>{renderedBlocks}</TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </Box>
  );
};

export default LatestBlockTable;
