import {
  Box,
  Container,
  Stack,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { filter } from "lodash";
import React, { useEffect, useState } from "react";
import GeneralChip from "../general-chip.component";
import { Link } from "react-router-dom";
import DecodedValues from "./decoded-values.component";

const TransactionLogs = ({ events }) => {
  const [logsView, setLogsView] = useState("hex");
  const [hasDecodedLogs, setHasDecodedLogs] = useState(false);
  const [rawLogs, setRawLogs] = useState([]);
  useEffect(() => {
    if (events) {
      const decodedLogs = filter(
        events,
        (event) => event.decodedLog !== undefined
      );

      if (decodedLogs.length) {
        setHasDecodedLogs(true);
        setLogsView("decode");
      }

      setRawLogs([]);

      events.forEach((event, index) => {
        let rawLog = "";
        event.log.topics.forEach((log, index) => {
          rawLog += `[${index}]:     ${log}`;

          if (index + 1 < event.log.topics.length) {
            rawLog += `\n`;
          }
        });

        if (event.log.data && event.log.data !== "0x") {
          rawLog += `\n[data]:  ${event.log.data}`;
        }
        setRawLogs((current) => [...current, rawLog]);
      });
    }
  }, [events]);

  const handleLogsView = (e, newLogView) => {
    setLogsView(newLogView);
  };

  return (
    <Container maxWidth="xl" sx={{ paddingY: "2rem" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="singlePageTitle">Event Logs</Typography>

        <ToggleButtonGroup
          value={logsView}
          exclusive
          onChange={handleLogsView}
          aria-label="logs view"
        >
          <ToggleButton value="hex" aria-label="hex view">
            <Typography>Hex</Typography>
          </ToggleButton>
          <ToggleButton value="decode" aria-label="decode view">
            <Typography>Decode</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box sx={{ marginTop: "2rem" }}>
        {events.map((event, index) => (
          <Box
            sx={{
              marginY: "1rem",
              overflow: "hidden",
              padding: "1rem",
              boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            }}
            display={"flex"}
            flexDirection={"column"}
            gap={"1rem"}
            key={index}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box
                display={"flex"}
                alignItems={{ xs: "flex-start", md: "center" }}
                justifyContent={"flex-start"}
                flexDirection={{ xs: "column", md: "row" }}
                gap={"1rem"}
              >
                <GeneralChip
                  value={event.logIndex}
                  type={"success"}
                  varient={"singlePageItem"}
                />
                <Link to={`/address/${event.contractAddress}`}>
                  <Typography variant="transactionEventCotractAddress">
                    {event.contractAddress}
                  </Typography>
                </Link>
              </Box>

              {event.contractAddress !== event.contractName && (
                <Link to={`/address/${event.contractAddress}`}>
                  <Typography variant="transactionEventCotractAddress">
                    {event.contractName}
                  </Typography>
                </Link>
              )}
            </Stack>

            {logsView === "decode" && event.decodedLog && (
              <Stack direction={{ xs: "column", md: "row" }}>
                <Typography variant="singlePageItemBold">
                  {`${event.decodedLog?.name} (`}
                </Typography>

                {event.decodedLog?.events.map((param, index) => (
                  <Box display={"flex"} alignItems={"center"} key={index}>
                    <Typography
                      sx={{ paddingRight: "0.25rem" }}
                      variant="transactionLogParamName"
                    >
                      {param.name}
                    </Typography>
                    <Typography variant="transactionLogParamType">
                      {param.type}
                    </Typography>
                    {index !== event.decodedLog.events.length - 1 && (
                      <Typography sx={{ paddingRight: "0.25rem" }}>
                        ,
                      </Typography>
                    )}
                    {index === event.decodedLog.events.length - 1 && (
                      <Typography>{`)`}</Typography>
                    )}
                  </Box>
                ))}
              </Stack>
            )}

            <Box>
              {logsView == "decode" && event.decodedLog ? (
                <>
                  {event.decodedLog && (
                    <DecodedValues
                      type={"log"}
                      log={event.log}
                      params={event.decodedLog.events}
                    />
                  )}
                </>
              ) : logsView == "hex" || !event.decodedLog ? (
                <TextareaAutosize
                  value={rawLogs[index]}
                  minRows={1}
                  maxRows={Infinity}
                  disabled
                  style={{ width: "-webkit-fill-available", resize: "none" }}
                />
              ) : (
                ""
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default TransactionLogs;
