import { IconButton, Stack } from "@mui/material";
import React, { memo } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "./SnackbarProvider";
import { copyToClipboard } from "../utils/helper/helper";

const TableCellCopy = memo(({ children, type, content, color }) => {
  const { showSnackbar } = useSnackbar();

  const handleShowSnackbar = (e) => {
    e.preventDefault();
    copyToClipboard(content);

    showSnackbar(`The ${type} has been copied`, "success");
  };

  return (
    <Stack direction={"row"} alignItems={"center"} gap={".25rem"}>
      {children}
      <IconButton onClick={handleShowSnackbar}>
        <ContentCopyIcon
          sx={{ fontSize: ".95rem", color: `${color ? color : ""}` }}
        />
      </IconButton>
    </Stack>
  );
});

export default TableCellCopy;
