import {
  getNodeBlock,
  getNodeBlockHeader,
  getNodeLatestTransactions,
  getNodeTxsByAccount,
} from "../node/node-api.service";
import Web3 from "web3";
import {
  getContract,
  getLogsForContract,
  getMethodForContract,
} from "../contract/contract-resource.service";
import {
  getTxReceiptsByHashes,
  getNodeTxByHash,
} from "../node/node-api.service";
import { getBlock } from "../block/block-resource.service";
import { find, first, get, isString, map, reverse, sortBy } from "lodash";
import { setShowErrorDialog } from "../../App";

export const DEFAULT_SCOPE_SIZE = 100000000; // max block range scope
export const DEFAULT_PAGE_SIZE = 10;

export async function getTxsByBlock(blockId, page, pageSize) {
  try {
    const block = await getNodeBlock(blockId);
    const txToFetch = block.transactions.slice(
      (page - 1) * pageSize,
      page * pageSize
    );

    const promises = [];
    txToFetch.forEach((hash) => {
      promises.push(getTxByHash(hash));
    });

    const txs = await Promise.all(promises);
    const blockTransactions = {
      blockId: blockId,
      transactions: txs,
      total: block.transactions.length,
    };

    return blockTransactions;
  } catch (error) {
    setShowErrorDialog();
  }
}

export async function getTxByHash(hash, includeBlock = false) {
  const tx = await getNodeTxByHash(hash);
  const richTx = await mapTransactions([tx], true, true, includeBlock);

  return first(richTx);
}

export async function getTxByAddress(
  address,
  type,
  page = 0,
  pageSize = 0,
  searchFromBlock = 0
) {
  if (!page) page = 1;
  if (!pageSize) pageSize = DEFAULT_PAGE_SIZE;

  const web3Txs = await getNodeTxsByAccount(
    address,
    page,
    pageSize,
    type,
    searchFromBlock,
    DEFAULT_SCOPE_SIZE
  );
  const txs = sortTransactions(web3Txs.results);

  const addressTransactions = {
    address,
    page,
    pageSize,
    isEmpty: web3Txs.isEmpty,
    total: web3Txs.total,
    transactions: await mapTransactions(txs, true, true),
  };
  return addressTransactions;
}

export async function getLatestTransactions(
  page = undefined,
  pageSize = undefined,
  searchFromBlock = undefined,
  scopeSize = undefined
) {
  try {
    if (!page) page = 1;
    if (!pageSize) pageSize = DEFAULT_PAGE_SIZE;
    if (!scopeSize) scopeSize = -DEFAULT_SCOPE_SIZE;
    const blockHeader = await getNodeBlockHeader();
    const web3Txs = await getNodeLatestTransactions(
      page,
      pageSize,
      blockHeader,
      scopeSize
    );
    const txs = sortTransactions(web3Txs.results);

    const addressTransactions = {
      address: undefined,
      page,
      pageSize,
      isEmpty: web3Txs.isEmpty,
      total: web3Txs.total,
      transactions: await mapTransactions(txs, true, true),
    };
    return addressTransactions;
  } catch (error) {
    setShowErrorDialog();
  }
}

function sortTransactions(txs) {
  return reverse(
    sortBy(txs, (tx) => {
      return tx.blockNumber;
    })
  );
}

async function mapTransactions(
  txs,
  includeReceipts = undefined,
  discoverContracts = undefined,
  includeBlock = undefined
) {
  if (txs.length === 0) {
    return Promise.resolve([]);
  }

  const waitForPromises = [];

  const mappedTransactions = map(txs, (tx) => {
    let txGasPrice = 0;
    if (isString(tx.gasPrice) && tx.gasPrice.startsWith("0x"))
      txGasPrice = Web3.utils.hexToNumber(tx.gasPrice);
    if (isString(tx.gasPrice)) txGasPrice = parseInt(tx.gasPrice, 10);

    if (!tx.hash && get(tx, "transactionHash")) {
      tx.hash = get(tx, "transactionHash");
    }

    const mappedTx = {
      data: tx,
      type: "transaction",
      method: undefined,
      txFee: tx.gas * txGasPrice,
    };

    if (tx.to === null && tx.input) {
      mappedTx.type = "contract-create";
    } else if (tx.input && tx.input !== "0x") {
      mappedTx.method = tx.to
        ? getMethodForContract(tx.to, tx.input)
        : { name: tx.input.substr(0, 10) };
      mappedTx.type = "contract-call";
    }

    return mappedTx;
  });

  if (includeReceipts) {
    const hashes = map(mappedTransactions, (tx) => tx.data.hash);
    const receipts = await getTxReceiptsByHashes(hashes);

    mappedTransactions.forEach(async (tx) => {
      tx.receipt = find(receipts, { transactionHash: tx.data.hash });

      if (tx.receipt && tx.receipt.status && tx.receipt.logs.length > 0) {
        try {
          tx.events = getLogsForContract(tx.receipt.to, tx.receipt.logs);
        } catch (error) {
          console.log("Tranaction resource service - Line 184");
          console.error(error);
        }
      }
    });
  }

  //discover contracts
  if (discoverContracts && includeReceipts) {
    mappedTransactions.forEach(async (tx) => {
      if (tx.type === "contract-call" && tx.receipt && tx.receipt.status) {
        waitForPromises.push(getContract(tx.receipt.to));
      }
    });
    await Promise.all(waitForPromises);
  }

  if (includeBlock) {
    mappedTransactions.forEach(async (tx) => {
      if (tx.data.blockNumber) {
        tx.block = await getBlock(tx.data.blockNumber);
      }
    });
  }

  return mappedTransactions;
}
