import { combineReducers } from "redux";
import { sessionReducer } from "./session/session-reducer";
import { blockReducer } from "./block/block.reducer";
import { contractReducer } from "./contract/contract.reducer";
import { sep20ContractReducer } from "./sep20/sep20.reducer";

export const rootReducer = combineReducers({
  session: sessionReducer,
  block: blockReducer,
  contract: contractReducer,
  sep20Contracts: sep20ContractReducer,
});
