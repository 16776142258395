import CONTRACT_ACTION_TYPES from "./contract.types";

const INITIAL_STATE = null;

export const contractReducer = (state = INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CONTRACT_ACTION_TYPES.CONTRACT_SET:
      return {
        contracts: payload.contracts,
      };
    default:
      return state;
  }
};
