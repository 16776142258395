import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const EnsNameResult = ({ props }) => {
  const { innerProps, data } = props;
  return (
    <Link to={data.url}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        {...innerProps}
        padding={"0.5rem"}
        gap={".5rem"}
      >
        <Typography variant="singlePageItemBold">{data.label}</Typography>
        <Typography variant="singlePageItem" sx={{ fontSize: ".75rem" }}>
          {data.ensName}
        </Typography>
        <Typography variant="singlePageItem" sx={{ fontSize: ".75rem" }}>
          {data.ensAddress}
        </Typography>
      </Box>
    </Link>
  );
};

export default EnsNameResult;
