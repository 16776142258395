import { store } from "../../store/store";
import { block } from "../../store/block/block.selector";
import Web3 from "web3";
import { toChecksumAddress } from "ethereum-checksum-address";
import { ensAddressLookup } from "../node/node-api.service";
import { getAddressInfo } from "../address/address-resource.service";
import { getTxByHash } from "../transaction/transaction-resource.service";
import { getBlock } from "../block/block-resource.service";
const searchHistory = [];
var currentSearchResult = undefined;

export const addResult = (result = undefined) => {
  if (
    currentSearchResult &&
    currentSearchResult.type !== "empty-result" &&
    currentSearchResult.type !== "undefined"
  ) {
    searchHistory.unshift(currentSearchResult);
  }

  currentSearchResult = result ?? { type: "undefined" };
};

export const getHistory = () => {
  return searchHistory;
};

export const query = async (query) => {
  const blockHeight = store.getState(block)?.block.blockHeight;

  const txRegex = /^0x([A-Fa-f0-9]{64})$/; //64 char hex with prefix
  const blockRegex = /^\d+$/; // positive number

  let isAddress = false;
  try {
    isAddress = Web3.utils.isAddress(toChecksumAddress(query));
  } catch {}

  let isEnsName = false;
  let ensAddress = "";
  try {
    ensAddress = await ensAddressLookup(query.toLowerCase());
    isEnsName = !!ensAddress;
  } catch {}

  let isTx = txRegex.test(query);

  let blocknumber = blockRegex.test(query) ? parseInt(query, 10) : undefined;
  if (!blocknumber && Web3.utils.isHexStrict(query) && query.length < 8) {
    blocknumber = Web3.utils.hexToNumber(query);
  }

  if (!isAddress && !isEnsName && !isTx && !blocknumber) {
    const emptyResult = {
      query,
      type: "empty-result",
    };

    addResult(emptyResult);
  }

  if (isAddress) {
    const address = toChecksumAddress(query);
    const addressResult = {
      query: address,
      type: "address",
      url: `/address/${address}`,
      data: await getAddressInfo(address),
    };
    addResult(addressResult);
  }

  if (isEnsName) {
    const addressResult = {
      query: query,
      type: "ensName",
      url: `/address/${ensAddress}`,
      data: { ensName: query, ensAddress: ensAddress },
    };
    addResult(addressResult);
  }

  if (isTx) {
    addResult({
      query,
      type: "tx",
      url: `/tx/${query}`,
      data: await getTxByHash(query, true),
    });
  }

  if (blocknumber && blocknumber > 0 && blocknumber < blockHeight) {
    const blockResult = {
      query,
      type: "block",
      url: `/block/${blocknumber}`,
      data: await getBlock(blocknumber),
    };
    addResult(blockResult);
  }

  const lastResult = currentSearchResult;
  if (lastResult) {
    return [lastResult];
  }

  return [];
};

export const generateBlockResult = (info) => {
  return {
    type: info.type,
    label: `${info.type.charAt(0).toUpperCase() + info.type.slice(1)} ${
      info.data.number
    }`,
    url: info.url,
    hashCode: info.data.hash,
    transactions: `${info.data.transactions.length} transactions`,
    timestamp: `${new Date(info.data.timestamp * 1000).toLocaleString("UTC", {
      dateStyle: "medium",
      timeStyle: "medium",
    })}`,
  };
};
export const generateAddressResult = (info) => {
  return {
    type: info.type,
    label: info.type.charAt(0).toUpperCase() + info.type.slice(1),
    id: info.query,
    balance: info.data.balance,
    txCount: info.data.txCount,
    url: info.url,
  };
};

export const generateTxResult = (info) => {
  return {
    type: info.type,
    label: "Transaction",
    txType: info.data.type,
    url: info.url,
    hashCode: info.data.data.blockHash,
    blockNumber: info.data.data.blockNumber,
    balance: info.data.data.value,
    timestamp: info.data.block
      ? `${new Date(info.data.block.timestamp * 1000).toLocaleString("UTC", {
          dateStyle: "medium",
          timeStyle: "medium",
        })}`
      : "",
  };
};

export const generateEmptyResult = (info) => {
  return {
    label: "Empty",
    query: info.query,
  };
};

export const generateEnsNameResult = (info) => {
  return {
    type: info.type,
    label: "LNS Domain Name",
    txType: info.data.type,
    ensName: info.data.data.ensName,
    ensAddress: info.data.data.ensAddress,
    url: info.url,
  };
};
