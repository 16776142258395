import {
  find,
  filter as loFilter,
  orderBy,
  uniqBy,
  forEach,
  indexOf,
  map,
} from "lodash";

import {
  getNodeBlocks,
  getNodeBlockHeader,
  getNodeBlock,
} from "../node/node-api.service";

var blocksList = [];

export async function getBlock(blockId) {
  const cachedBlock = find(blocksList, { number: blockId });

  if (cachedBlock) {
    return cachedBlock;
  }
  let block = null;

  try {
    block = await getNodeBlock(blockId);
    blocksList.push(block);
    blocksList = orderBy(blocksList, ["number"], ["desc"]);
    blocksList = uniqBy(blocksList, "number");
    return block;
  } catch (error) {
    return null;
  }
}

export async function getBlocks(blockIds) {
  const cachedBlocks = loFilter(blocksList, (block) =>
    blockIds.includes(block.number)
  );
  const blocksToFetch = loFilter(
    blockIds,
    (id) => !find(cachedBlocks, { number: id })
  );

  let fetchedBlocks = [];

  if (blocksToFetch.length) {
    fetchedBlocks = await getNodeBlocks(blocksToFetch);
  }

  blocksList = blocksList.concat(fetchedBlocks);

  blocksList = orderBy(blocksList, ["number"], ["desc"]);
  blocksList = uniqBy(blocksList, "number");

  const blocksToReturn = cachedBlocks.concat(fetchedBlocks);

  return orderBy(blocksToReturn, ["number"], ["desc"]);
}

export async function getLatestBlocks(count, height = undefined) {
  let blockHeight = height ?? 0;

  if (!blockHeight) {
    blockHeight = await getNodeBlockHeader();
  }

  if (!blockHeight) {
    return Promise.reject();
  }

  const blockRequests = [];
  const ids = [];

  for (let i = 0; i < count; i++) {
    if (!find(blocksList, { number: blockHeight - i })) {
      ids.push(blockHeight - i);
    }
  }
  const blocks = ids.length && (await getNodeBlocks(ids));
  forEach(blocks, (block) => {
    if (!find(blocksList, { number: block.number })) {
      blocksList.push(block);
    }
  });

  blocksList = orderBy(blocksList, ["number"], ["desc"]);
  blocksList = uniqBy(blocksList, "number");

  return loFilter(blocksList, (block) => block.number > blockHeight - count);
}
