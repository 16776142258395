import { toChecksumAddress } from "ethereum-checksum-address";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ensNameLookup,
  getAccountBalance,
  getCode,
} from "../services/node/node-api.service";
import Web3 from "web3";
import { getAddressName } from "../services/address/address-resource.service";
import {
  getContract,
  getContractArray,
  loadContracts,
} from "../services/contract/contract-resource.service";
import { useSelector } from "react-redux";
import { contract } from "../store/contract/contract.selector";
import { Sep20HelperService } from "../services/setp-20/sep-20-helper.service";
import {
  convertValue,
  numberWithCommas,
} from "../services/util/util-helper.service";
import { find } from "lodash";
import Spinner from "../components/spinner.component";
import AddressDetail from "../components/address/address-detail.component";
import {
  Box,
  Container,
  Stack,
  Tab,
  Tabs,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import ChecksumAddress from "../components/checksum-address.component";
import HashIcon from "../components/hash-icon.component";
import GeneralChip from "../components/general-chip.component";
import AddressTokensSep20List from "../components/address-tokens-sep20-list.component";
import PropTypes from "prop-types";
import AddressTransactions from "../components/address/address-transactions.component";
import AddressEventLog from "../components/address/address-event-log.component";
import TableCellCopy from "../components/table-cell-copy.component";
import SafirLOGO from "../assets/images/safir.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`address-tabpanel-${index}`}
      aria-labelledby={`address-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AddressPage = () => {
  var sep20Helper = new Sep20HelperService();

  const addressId = useParams()?.addressId;
  const [address, setAddress] = useState("" | undefined);
  const [code, setCode] = useState("" | undefined);
  const [contractItem, setContractItem] = useState(undefined);
  const [contractName, setContractName] = useState("" | undefined);
  const [sep20Contract, setSep20Contract] = useState(undefined | null);
  const [sep20SupplyWhole, setSep20SupplyWhole] = useState("" | undefined);
  const [sep20SupplyFraction, setSep20SupplyFraction] = useState(
    "" | undefined,
  );
  const [ensName, setEnsName] = useState("" | undefined);
  const [IsInternalContract, setIsInternalContract] = useState(false);
  const [balance, setBalance] = useState("0");
  const [initialized, setInitialized] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    getAddressInfo();
  }, [addressId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getAddressInfo() {
    if (addressId) {
      let tempAddress = toChecksumAddress(addressId);
      setAddress(tempAddress);
      setContractName(undefined);
      setSep20Contract(undefined);
      setSep20SupplyWhole(undefined);
      setSep20SupplyFraction(undefined);
      setEnsName(undefined);

      if (tempAddress) {
        if (tempAddress === "0x0000000000000000000000000000000000002711") {
          setIsInternalContract(true);
        }

        await ensNameLookup(tempAddress)
          .then((result) => {
            setEnsName(result);
          })
          .catch(() => {
            setEnsName("");
          });

        await getAccountBalance(tempAddress)
          .then((result) => {
            setBalance(result);
            setInitialized(true);
            setNotFound(false);
          })
          .catch(() => {
            setBalance("0");
            setInitialized(true);
            setNotFound(false);
          });

        await getCode(tempAddress).then(async (code) => {
          setCode(Web3.utils.stripHexPrefix(code));

          if (code && tempAddress) {
            const getContractItem = await getContract(tempAddress);

            const contractArray = getContractArray();
            let cName = getAddressName(tempAddress);
            setContractName(cName);
            setContractItem(getContractItem);
            if (getContractItem?.type === "sep20") {
              const contract = find(contractArray, {
                address: toChecksumAddress(tempAddress),
              });
              if (tempAddress && contract) {
                if (!contract.sep20) {
                  contract.sep20 =
                    await sep20Helper.getSep20ContractInformation(
                      tempAddress,
                      contract.logo,
                    );
                }

                if (contract.sep20) {
                  let logo = {};
                  if (contract.sep20.symbol != "SAFIR") {
                    let logoResponse = await fetch(
                      `https://price.zeniq.services/v2/info/image/${contract.sep20.symbol.toLowerCase()}`,
                    );
                    if (logoResponse.ok) {
                      logo = await logoResponse?.json();
                    } else logo = null;
                  } else {
                    logo.thumb = SafirLOGO;
                  }

                  contract.sep20.logoItem = logo;
                  setSep20Contract(contract.sep20);
                  const sep20Supply = numberWithCommas(
                    convertValue(
                      contract.sep20.totalSupply,
                      contract.sep20.decimals,
                    ),
                  );
                  const splitBalance = sep20Supply.split(".");
                  setSep20SupplyWhole(splitBalance[0]);
                  setSep20SupplyFraction(splitBalance[1] ?? undefined);
                }
              }
            }
          } else {
            setContractItem(undefined);
          }
        });
        setLoading(false);
      }
    }
  }

  function a11yProps(index) {
    return {
      id: `address-tab-${index}`,
      "aria-controls": `address-tabpanel-${index}`,
    };
  }

  function handleChangeTab(e, newValue) {
    setSelectedTabIndex(newValue);
  }

  return (
    <>
      {loading ? (
        <Spinner width={"100%"} height={"540px"} iconSize={"40px"} />
      ) : (
        <>
          <Container
            maxWidth="xl"
            sx={{
              marginY: "2rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={"1rem"}
            >
              <Box display={"flex"} alignItems={"center"} flex={"row"}>
                {!code ? (
                  <Typography variant="singlePageTitle">Address</Typography>
                ) : (
                  <Typography variant="singlePageTitle">
                    {" "}
                    {sep20Contract && "ZEN20"} Contract
                  </Typography>
                )}
              </Box>

              <Box display={"flex"} alignItems={"center"} flex={"column"}>
                <Stack direction={"column"} alignItems={"center"} gap={".5rem"}>
                  <TableCellCopy type={"Address"} content={address}>
                    <Typography variant="singlePageTitle">
                      {<ChecksumAddress value={address} />}
                    </Typography>
                  </TableCellCopy>
                  {!!ensName && (
                    <a
                      className="green_link"
                      target="_blank"
                      href={`https://app.bch.domains/name/${ensName}`}
                    >
                      {ensName}
                    </a>
                  )}

                  {contractName && contractName !== address && (
                    <Typography>{contractName}</Typography>
                  )}
                </Stack>
              </Box>

              <Box display={"flex"} alignItems={"center"} flex={"row"}>
                {contract ? (
                  contract.logo ? (
                    <img
                      src={`/assets/images/tokens/${contract.address}.png`}
                      alt="contract_address_logo"
                    />
                  ) : (
                    <HashIcon address={address} diameter={64} />
                  )
                ) : (
                  <HashIcon address={address} diameter={64} />
                )}
              </Box>
            </Stack>

            {sep20Contract && (
              <Box
                marginY={"1rem"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"center"}
                width={{ xs: "100%", md: "50%" }}
              >
                <Typography variant="singlePageItemBold" sx={{ mb: "1rem" }}>
                  ZEN20 Information
                </Typography>
                <Stack
                  paddingY={".25rem"}
                  borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  gap={"2rem"}
                  width={"100%"}
                >
                  <Typography
                    variant="singlePageItemBold"
                    sx={{
                      flex: { xs: ".3", md: ".2" },
                      fontSize: { xs: ".75rem", md: "" },
                    }}
                  >
                    Name
                  </Typography>

                  <Stack
                    direction={"row"}
                    gap={".5rem"}
                    alignItems={"center"}
                    sx={{ flex: 1, fontSize: { xs: ".75rem", md: "" } }}
                  >
                    {sep20Contract?.logoItem && (
                      <img
                        src={sep20Contract.logoItem.thumb}
                        width={20}
                        height={20}
                        style={{ borderRadius: "50%" }}
                      />
                    )}
                    <Typography
                      variant="singlePageItem"
                      sx={{ fontSize: { xs: ".75rem", md: "" } }}
                    >
                      {sep20Contract?.name}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  paddingY={".25rem"}
                  borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  gap={"2rem"}
                  width={"100%"}
                >
                  <Typography
                    variant="singlePageItemBold"
                    sx={{
                      flex: { xs: ".3", md: ".2" },
                      fontSize: { xs: ".75rem", md: "" },
                    }}
                  >
                    Symbol
                  </Typography>

                  <Stack
                    direction={"row"}
                    gap={".5rem"}
                    alignItems={"center"}
                    sx={{ flex: 1, fontSize: { xs: ".75rem", md: "" } }}
                  >
                    {sep20Contract?.logoItem && (
                      <img
                        src={sep20Contract.logoItem.thumb}
                        width={20}
                        height={20}
                        style={{ borderRadius: "50%" }}
                      />
                    )}
                    <Typography
                      variant="singlePageItem"
                      sx={{ fontSize: { xs: ".75rem", md: "" } }}
                    >
                      {sep20Contract?.symbol}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  paddingY={".25rem"}
                  borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  gap={"2rem"}
                  width={"100%"}
                >
                  <Typography
                    variant="singlePageItemBold"
                    sx={{
                      flex: { xs: ".3", md: ".2" },
                      fontSize: { xs: ".75rem", md: "" },
                    }}
                  >
                    Total Supply
                  </Typography>

                  <Typography
                    variant="singlePageItem"
                    sx={{ flex: 1, fontSize: { xs: ".75rem", md: "" } }}
                  >
                    <Typography
                      variant="singlePageItemBold"
                      sx={{ fontSize: { xs: ".75rem", md: "" } }}
                    >
                      {sep20SupplyWhole}
                    </Typography>
                    {sep20SupplyFraction && `.${sep20SupplyFraction}`}
                  </Typography>
                </Stack>
                <Stack
                  paddingY={".25rem"}
                  borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  gap={"2rem"}
                  width={"100%"}
                >
                  <Typography
                    variant="singlePageItemBold"
                    sx={{
                      flex: { xs: ".3", md: ".2" },
                      fontSize: { xs: ".75rem", md: "" },
                    }}
                  >
                    Decimals
                  </Typography>

                  <Typography
                    variant="singlePageItem"
                    sx={{ flex: 1, fontSize: { xs: ".75rem", md: "" } }}
                  >
                    {sep20Contract?.decimals}
                  </Typography>
                </Stack>
              </Box>
            )}

            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                gap={"2rem"}
              >
                <Typography variant="singlePageTableTitle">
                  Account balance
                </Typography>

                <GeneralChip
                  value={`${Web3.utils.fromWei(balance, "ether")} ZENIQ`}
                  type={"success"}
                  varient={"singlePageItem"}
                />
              </Stack>

              <Box
                sx={{
                  width: { xs: "100%", sm: "60%", md: "50%", lg: "30%" },
                  mt: { xs: "2rem", md: "" },
                }}
              >
                {(initialized && !contractName) ||
                  (contractName == address && (
                    <AddressTokensSep20List address={address} />
                  ))}
              </Box>
            </Stack>

            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={selectedTabIndex}
                onChange={handleChangeTab}
                aria-label="address tab"
              >
                <Tab label="Transactions" {...a11yProps(0)} />
                {(code || IsInternalContract) && (
                  <Tab label="Event Logs" {...a11yProps(1)} />
                )}

                {code && <Tab label="Contract Code" {...a11yProps(2)} />}
              </Tabs>

              <TabPanel
                value={selectedTabIndex}
                index={0}
                sx={{ Box: { padding: 0 } }}
              >
                <AddressTransactions address={address} />
              </TabPanel>
              <TabPanel value={selectedTabIndex} index={1}>
                <AddressEventLog address={address} />
              </TabPanel>
              <TabPanel value={selectedTabIndex} index={2}>
                <TextareaAutosize
                  value={code}
                  minRows={1}
                  maxRows={Infinity}
                  disabled
                  style={{
                    width: "-webkit-fill-available",
                    resize: "none",
                  }}
                />
              </TabPanel>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default AddressPage;
