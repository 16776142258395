import config from "../../assets/configs/nodes.json";

export async function initialSession() {
  // let loadedConfig = config[Math.floor(Math.random() * config.length)];

  let loadedConfig = await checkConnection(config);
  const storedConfig = localStorage.getItem("connection-config");

  if (storedConfig && JSON.parse(storedConfig)) {
    const sc = JSON.parse(storedConfig);
    if (!sc?.apiEndpoint.startsWith("wss://smart")) {
      localStorage.removeItem("connection-config");
    } else loadedConfig = sc;
  }
  return Promise.resolve(loadedConfig);
}

function checkConnection(configs) {
  return new Promise((resolve) => {
    for (let i = 0; i < configs.length; i++) {
      const ws = new WebSocket(configs[i].apiEndpoint);
      ws.onopen = function (e) {
        resolve(configs[i]);
      };
      break;
    }
  });
}
