import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import logger from "redux-logger";

import { rootReducer } from "./root-reducer";
import loggerMiddleware from "../utils/middleware/logger.utils";
import thunkMiddleware from "redux-thunk";
import monitorReducerEnhancer from "../utils/enhancers/monitorReducer";
import { contractsAddABI } from "../services/contract/contract-resource.service";

// const middleWares = [process.env.NODE_ENV === "development" && logger].filter(
//   Boolean
// );

// const composedEnhancers = compose(applyMiddleware(...middleWares));

const contractMiddleWare = (store) => (next) => (action) => {
  const previousState = store.getState();
  const result = next(action);
  const currentState = store.getState();

  if (previousState !== currentState) {
    contractsAddABI(currentState);
  }

  return result;
};

const middlewareEnhancer = applyMiddleware(
  loggerMiddleware,
  thunkMiddleware,
  contractMiddleWare
);
const composedEnhancers = compose(middlewareEnhancer, monitorReducerEnhancer);
// const composedEnhancers = compose(applyMiddleware(...contractMiddleWare));

export const store = createStore(rootReducer, undefined);
