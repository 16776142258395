import SEP20_CONTRACT_ACTION_TYPES from "./sep20.types";

const INITIAL_STATE = {
  sep20Contracts: [],
};

export const sep20ContractReducer = (state = INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEP20_CONTRACT_ACTION_TYPES.SEP20_CONTRACT_SET:
      return {
        ...state,
        sep20Contracts: [...state.sep20Contracts, payload.sep20Contracts],
      };

    default:
      return state;
  }
};
