import React, { createContext, useContext, useState } from "react";
import GlobalSnackbar from "./GlobalSnackbar";

export const SnackbarContext = createContext();

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

const SnackbarProvider = ({ children }) => {
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const showSnackbar = (message, severity) => {
    setSnackbarData({ open: true, message, severity });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarData({ ...snackbarData, open: false });
  };

  return (
    <SnackbarContext.Provider
      value={{ snackbarData, showSnackbar, handleClose }}
    >
      {children}
      <GlobalSnackbar />
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
