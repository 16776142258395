import Web3 from "web3";
import {
  BlockNumber,
  HttpProvider,
  Transaction,
  TransactionConfig,
  TransactionReceipt,
  WebsocketProvider,
} from "web3-core";
import { sbchExtensions } from "./web3-sbch.extention";
import { Block } from "web3-eth";
// const { SBCHSource } = require("../../node-api.service");

const STAKE_CONTRACT = "0x2710";

export class Web3Connector {
  constructor(endpoint, type) {
    this.web3 = undefined;
    this.type = type;
    this.endpoint = endpoint;
    try {
      if (!endpoint) {
        throw new Error("No endpoint provided");
      }

      if (!type) {
        throw new Error("No connector type provided");
      }

      if (type === "ws") {
        if (this.web3) {
          console.log("[Node Adapter:Web3] Dissconnect web3", endpoint);

          const provider = this.web3.currentProvider;
          if (provider.connected) {
            provider.disconnect(1000, "Reload Provider");
          }
        }

        console.log("[Node Adapter:Web3] Connecting to node via WS");
        this.web3 = new Web3(new Web3.providers.WebsocketProvider(endpoint));
      }

      if (type === "http") {
        console.log("[Node Adapter:Web3] Connecting to node via HTTP");
        this.web3 = new Web3(new Web3.providers.HttpProvider(endpoint));
      }

      console.log("[Node  Adapter:Web3] Loading SBCH web3 extensions");
      if (this.web3) {
        this.web3.extend(sbchExtensions);
        // this.web3.currentProvider;
      }
    } catch (error) {
      console.log("[Node Adapter:Web3] Error connecting to node", error);

      throw new Error("Error connecting to node");
    }
  }

  getWeb3() {
    return this.web3;
  }

  getChainId() {
    if (!this.web3) return Promise.reject();

    return this.web3.eth.getChainId();
  }

  getBlockNumber() {
    if (!this.web3) return Promise.reject();
    return this.web3.eth.getBlockNumber();
  }

  getBlock(blockId) {
    if (!this.web3) return Promise.reject();
    return this.web3.eth.getBlock(blockId);
  }

  getBlocks(blockIds) {
    if (!this.web3) return Promise.reject();

    const batch = new this.web3.BatchRequest();
    const promises = blockIds.map((id) => {
      return new Promise((res, rej) => {
        if (this.web3) {
          const getBlock = this.web3.eth.getBlock;
          const req = getBlock.request(id, (err, data) => {
            if (err) rej(err);
            else res(data);
          });
          batch.add(req);
        } else {
          rej(null);
        }
      });
    });

    batch.execute();
    return Promise.all(promises);
  }

  getTxListByHeight(blockId) {
    if (!this.web3) return Promise.reject();
    return this.web3.sbch.getTxListByHeight(blockId);
  }

  async getTxListByHeights(blockIds) {
    if (!this.web3) return Promise.reject();

    const batch = new this.web3.BatchRequest();
    const promises = blockIds.map((id) => {
      return new Promise((res, rej) => {
        if (this.web3) {
          const getTxListByHeight = this.web3.sbch.getTxListByHeight;
          const req = getTxListByHeight.request(id, (err, data) => {
            if (err) rej(err);
            else res(data);
          });
          batch.add(req);
        } else {
          rej(null);
        }
      });
    });

    batch.execute();

    const txs = await Promise.all(promises);

    return txs.reduce((a, b) => a.concat(b), []);
    return Promise.all(promises);
  }

  getTxListByHeightWithRange(blockId, start, end) {
    if (!this.web3) return Promise.reject();
    if (this.web3.sbch) {
      return this.web3.sbch?.getTxListByHeightWithRange(
        blockId,
        Web3.utils.toHex(start.toString()),
        Web3.utils.toHex(end.toString())
      );
    }
    return Promise.reject(false);
  }

  getTransaction(hash) {
    if (!this.web3) return Promise.reject();
    return this.web3.eth.getTransaction(hash);
  }

  getTransactions(hashes) {
    if (!this.web3) return Promise.reject();

    const batch = new this.web3.BatchRequest();
    const promises = hashes.map((id) => {
      return new Promise((res, rej) => {
        if (this.web3) {
          const getTransaction = this.web3.eth.getTransaction;
          const req = getTransaction.request(id, (err, data) => {
            if (err) rej(err);
            else res(data);
          });
          batch.add(req);
        } else {
          rej(null);
        }
      });
    });

    batch.execute();
    return Promise.all(promises);
  }

  queryTxByAddr(address, from, to, limit = 0) {
    if (!this.web3) return Promise.reject();
    if (this.web3.sbch) {
      return this.web3.sbch?.queryTxByAddr(
        address,
        from,
        to,
        Web3.utils.toHex(limit.toString())
      );
    }
    return Promise.reject(false);
  }

  queryTxBySrc(address, from, to, limit = 0) {
    if (!this.web3) return Promise.reject();
    if (this.web3.sbch) {
      return this.web3.sbch?.queryTxBySrc(
        address,
        from,
        to,
        Web3.utils.toHex(limit.toString())
      );
    }
    return Promise.reject(false);
  }

  queryTxByDst(address, from, to, limit = 0) {
    if (!this.web3) return Promise.reject();
    if (this.web3.sbch) {
      return this.web3.sbch?.queryTxByDst(
        address,
        from,
        to,
        Web3.utils.toHex(limit.toString())
      );
    }
    return Promise.reject(false);
  }

  getTransactionCount(address, type = "both") {
    if (!this.web3) return Promise.reject();

    if (this.web3.sbch) {
      return this.web3.sbch.getAddressCount(type, address);
    }
    return Promise.reject(false);
  }

  getSep20TransactionCount(address, sep20Contract, type) {
    if (!this.web3) return Promise.reject();

    if (this.web3.sbch) {
      return this.web3.sbch.getSep20AddressCount(type, sep20Contract, address);
    }
    return Promise.reject(false);
  }

  getBalance(address) {
    if (!this.web3) return Promise.reject();
    return this.web3.eth.getBalance(address);
  }

  getCode(address) {
    if (!this.web3) return Promise.reject();

    return this.web3.eth.getCode(address);
  }

  getTransactionReceipt(hash) {
    if (!this.web3) return Promise.reject();
    return this.web3.eth.getTransactionReceipt(hash);
  }

  getTransactionReceipts(hashes) {
    if (!this.web3) return Promise.reject();

    const batch = new this.web3.BatchRequest();
    const promises = hashes.map((hash) => {
      return new Promise((res, rej) => {
        if (this.web3) {
          const getReceipt = this.web3.eth.getTransactionReceipt;
          const req = getReceipt.request(hash, (err, data) => {
            if (err) rej(err);
            else res(data);
          });
          batch.add(req);
        } else {
          rej(null);
        }
      });
    });

    batch.execute();
    return Promise.all(promises);
  }

  call(transactionConfig) {
    if (!this.web3) return Promise.reject();
    return this.web3.eth.call(transactionConfig);
  }

  callMultiple(transactionConfigs) {
    if (!this.web3) return Promise.reject();

    const batch = new this.web3.BatchRequest();
    const promises = transactionConfigs.map((transactionConfig) => {
      return new Promise((res, rej) => {
        if (this.web3) {
          const call = this.web3.eth.call;
          const req = call.request(transactionConfig, (err, data) => {
            if (err) rej(err);
            else res(data);
          });
          batch.add(req);
        } else {
          rej(null);
        }
      });
    });

    batch.execute();
    return Promise.all(promises);
  }

  queryLogs(address, data, start, end, limit) {
    if (!this.web3) return Promise.reject();
    return this.web3.sbch.queryLogs(address, data, start, end, limit);
  }

  async queryAddressLogs(address, start, end) {
    if (!this.web3) return Promise.reject();
    return this.web3?.sbch.queryLogs(
      address,
      [],
      "0x1",
      "latest",
      Web3.utils.toHex(32)
    );
  }

  decodeParameter(datType, call) {
    if (!this.web3) return Promise.reject();
    return this.web3.eth.abi.decodeParameter(datType, call);
  }

  getContractInstant(abi, contractAddress) {
    return new this.web3.eth.Contract(abi, contractAddress);
  }
}
