import { Typography } from "@mui/material";
import React from "react";

const DRIFT_COMPENTSATION = 0;

const TimeElapsed = ({ timestamp }) => {
  const seconds =
    (new Date().getTime() - (Number(timestamp) + DRIFT_COMPENTSATION) * 1000) /
    1000;
  const h = Math.floor(Math.round(seconds) / 3600);
  const m = Math.floor((Math.round(seconds) % 3600) / 60);
  const s = Math.floor((Math.round(seconds) % 3600) % 60);

  const hDisplay = h > 0 ? h + (h == 1 ? " hour" : " hours") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " minute" : " minutes ") : "";
  const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

  return hDisplay ? hDisplay : mDisplay ? mDisplay : sDisplay;
};

export default TimeElapsed;
