import {
  Autocomplete,
  Box,
  IconButton,
  Input,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import {
  generateAddressResult,
  generateBlockResult,
  generateEmptyResult,
  generateEnsNameResult,
  generateTxResult,
  getHistory,
  query,
} from "../../services/search/search.service";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import { dateOption } from "../../utils/constants/constants";
import TruncateAddress from "../truncate-address.component";
import { Link, useNavigate } from "react-router-dom";
import BlockResult from "./block-result.component";
import AddressResult from "./address-result.component";
import TxResult from "./tx-result.component";
import EnsNameResult from "./ens-name-result.component";
import EmptyResult from "./empty-result.component";

const SEARCH_RESULT_TYPES = {
  BLOCK_RESULT: "block",
  TX_RESULT: "tx",
  ENS_NAME_RESULT: "ensName",
  ADDRESS_RESULT: "address",
  EMPTY_RESULT: "empty-result",
};

const SearchBar = () => {
  const [searchText, setSearchText] = useState("");
  const [currentTempOption, setCurrentTempOption] = useState({});
  const navigate = useNavigate();

  const debounceFn = useCallback(debounce(handleSearchQuery, 1000), []);

  async function handleSearchQuery(inputQuery, callback) {
    let currentArray = await query(inputQuery.replaceAll(/\s/g, ""));
    let history = getHistory();

    let historyOptions = [];

    if (history.length > 0)
      historyOptions = history.map((historyItem) => {
        switch (historyItem.type) {
          case SEARCH_RESULT_TYPES.BLOCK_RESULT:
            return generateBlockResult(historyItem);

          case SEARCH_RESULT_TYPES.ADDRESS_RESULT:
            return generateAddressResult(historyItem);

          case SEARCH_RESULT_TYPES.TX_RESULT:
            return generateTxResult(historyItem);
          case SEARCH_RESULT_TYPES.ENS_NAME_RESULT:
            return generateEnsNameResult(historyItem);

          default:
            return generateEmptyResult(historyItem);
        }
      });

    const currentOptions = currentArray.map((current) => {
      switch (current.type) {
        case SEARCH_RESULT_TYPES.BLOCK_RESULT:
          return generateBlockResult(current);

        case SEARCH_RESULT_TYPES.ADDRESS_RESULT:
          return generateAddressResult(current);

        case SEARCH_RESULT_TYPES.TX_RESULT:
          return generateTxResult(current);
        case SEARCH_RESULT_TYPES.ENS_NAME_RESULT:
          return generateEnsNameResult(current);

        default:
          return generateEmptyResult(current);
      }
    });

    const separatorOption = {
      label: "--------- History ---------",
      value: "separator",
      isSeparator: true,
    };

    setCurrentTempOption(currentOptions);

    const combinedOptions = [
      ...currentOptions,
      separatorOption,
      ...historyOptions,
    ];

    callback(combinedOptions);
  }

  const handleOnChange = (e) => {
    if (!e.target) return;
    const value = e.target.value;

    setSearchText(value);
    debounceFn(value);
  };

  const handleKeyDown = (e) => {
    if (currentTempOption[0]?.label == "Empty") return;
    if (e.key == "Enter") {
      if (
        currentTempOption[0] &&
        currentTempOption[0].url.includes(e.target.value)
      ) {
        navigate(currentTempOption[0].url);
      }
    }
  };

  const Option = (props) => {
    const { innerProps, label, data, options } = props;
    if (data.isSeparator) {
      return (
        <Box sx={{ padding: "2px", backgroundColor: "#efefef" }}>
          <Typography variant="singlePageItem">Recent searches</Typography>
        </Box>
      );
    }
    return (
      <>
        {data.type == SEARCH_RESULT_TYPES.BLOCK_RESULT ? (
          <BlockResult props={props} />
        ) : data.type == SEARCH_RESULT_TYPES.ADDRESS_RESULT ? (
          <AddressResult props={props} />
        ) : data.type == SEARCH_RESULT_TYPES.ENS_NAME_RESULT ? (
          <EnsNameResult props={props} />
        ) : data.type == SEARCH_RESULT_TYPES.TX_RESULT ? (
          <TxResult props={props} />
        ) : (
          <EmptyResult props={props} />
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.common.black,
        border: "1px solid #f7f7f7",
        borderRadius: "50px",
        maxWidth: "680px",
        width: { xs: "100%", md: "80%" },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        paddingLeft={"1rem"}
        sx={{ display: { xs: "none", sm: "flex" } }}
        width={"100%"}
      >
        <AsyncSelect
          cacheOptions
          value={searchText}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option,
          }}
          openMenuOnClick={false}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          placeholder="Search by address, txn hash, or block number"
          loadOptions={(inputValue, callback) => {
            debounceFn(inputValue, callback);
          }}
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              width: "100%",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "transparent",
              border: "unset",
              boxShadow: "none",
              width: "100%",
              ":hover": {
                border: "unset",
              },
            }),
            input: (provided) => ({
              ...provided,
              fontFamily: "Roboto",
              fontWeight: 300,
              fontSize: "1rem",
              color: "#fff",
              overflow: "hidden",
              cursor: "text",
              with: "100%",
            }),
            placeholder: (provided) => ({
              ...provided,
              color: "#f2f2f2",
              fontFamily: "Roboto",
              fontWeight: 300,
              fontSize: "1rem",
              opacity: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }),
            menu: (provided) => ({
              ...provided,
              width: 300,
              fontFamily: "Roboto",
              fontWeight: 300,
              fontSize: "1rem",
            }),
            menuList: (provided) => ({
              ...provided,
              width: 300,
              fontFamily: "Roboto",
              fontWeight: 300,
              fontSize: "1rem",
            }),
            singleValue: (provided) => ({
              ...provided,
              cursor: "pointer",
            }),
          }}
        />
      </Box>

      <Box paddingLeft={"1rem"} sx={{ display: { xs: "flex", sm: "none" } }}>
        <AsyncSelect
          cacheOptions
          value={searchText}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option,
          }}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          placeholder="Search by address, txn hash, or block number"
          loadOptions={(inputValue, callback) => {
            debounceFn(inputValue, callback);
          }}
          className="full-select"
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              width: "100%",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "transparent",
              border: "unset",
              boxShadow: "none",

              ":hover": {
                border: "unset",
              },
            }),
            input: (provided) => ({
              ...provided,
              fontFamily: "Roboto",
              fontWeight: 300,
              fontSize: "1rem",
              color: "#fff",
              overflow: "hidden",
              cursor: "text",
              " > input": {
                minWidth: "70px !important",
              },
            }),
            placeholder: (provided) => ({
              ...provided,
              color: "#f2f2f2",
              fontFamily: "Roboto",
              fontWeight: 300,
              fontSize: "1rem",
              opacity: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }),
            menu: (provided) => ({
              ...provided,
              width: 250,
              fontFamily: "Roboto",
              fontWeight: 300,
              fontSize: "1rem",
            }),
            menuList: (provided) => ({
              ...provided,
              width: 250,
              fontFamily: "Roboto",
              fontWeight: 300,
              fontSize: "1rem",
            }),
            singleValue: (provided) => ({
              ...provided,
              cursor: "pointer",
            }),
          }}
        />
      </Box>

      <IconButton
        color="custom"
        size="large"
        sx={{ paddingRight: "1rem", cursor: "auto" }}
      >
        <SearchIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
