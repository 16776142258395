import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import smallLogo from "../assets/images/small-logo.png";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SearchBar from "./search-bar/search-bar.component";
import coin from "../assets/images/coin.png";
const Navbar = () => {
  return (
    <Box sx={{ background: (theme) => theme.palette.common.black }}>
      <Container maxWidth="xl">
        <Grid container paddingY={".5rem"}>
          <Grid
            item
            md={3}
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Link to={"/"}>
              <img src={logo} alt="logo" />
            </Link>
          </Grid>

          <Grid
            item
            md={3}
            xs={3}
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "flex-end",
              textAlign: "center",
            }}
          >
            <Link to={"/"}>
              <img src={smallLogo} style={{ width: "70%" }} alt="logo" />
            </Link>
          </Grid>

          <Grid
            item
            md={6}
            xs={9}
            width={"80%"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SearchBar />
          </Grid>

          <Grid item md={3} sx={{ display: { xs: "none", md: "block" } }}>
            <Stack direction={"row"} justifyContent={"center"} gap={".5rem"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"center"}
                gap={"0.5rem"}
              >
                <Typography color={"custom.main"} variant="navHeading">
                  Smart Chain Explorer
                </Typography>
                <Typography variant="bureau">
                  The next generation of digital
                  <br />
                  asset management
                </Typography>
              </Box>
              <Box>
                <img src={coin} alt="coin_icon" width={70} height={70} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Navbar;
