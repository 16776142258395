export var showSnackbar = false;

export const setStorage = ({ key, value }) => {
  localStorage.setItem(key, value);
};

export const getStorage = (key) => {
  localStorage.getItem(key);
};

export const removeStorage = (key) => {
  localStorage.removeItem(key);
};

export const copyToClipboard = (targetText) => {
  if (!targetText) return;
  const tempInput = document.createElement("input");
  tempInput.value = targetText;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
};
