import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import styled, { keyframes } from "styled-components";
import LatestBlockItem from "./latest-block-item.component";
import Spinner from "../spinner.component";
import { Box, Container, Stack } from "@mui/system";
import { Typography } from "@mui/material";

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const AnimatedBlock = styled.div`
  animation: ${(props) => (props.leaving ? slideOut : slideIn)} 500ms ease-in;
`;

const LatestBlock = ({ blocks }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (blocks.length) setLoading(false);
  }, [blocks]);

  const handleSlideChanged = (e) => {
    // Handle slide change logic here if needed
  };

  const handleSlideIndexChanged = (e) => {
    // Handle slide index change logic here if needed
  };

  const responsive = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
      itemsFit: "contain",
    },
    1024: {
      items: 3,
    },
    1440: {
      items: 5,
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.latest",
        paddingY: "4rem",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="xl" sx={{ minHeight: "400px" }}>
        <Typography variant="mediumHeading">Latest Blocks</Typography>
        <Stack
          marginTop={"1.5rem"}
          flexWrap={"nowrap"}
          direction={"row"}
          justifyContent={"center"}
          gap={"2rem"}
          minHeight={"350px"}
          overflow={"hidden"}
        >
          {loading ? (
            <Box
              width={"100%"}
              height={"300px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Spinner />
            </Box>
          ) : (
            <AliceCarousel
              responsive={responsive}
              disableDotsControls
              disableButtonsControls
              items={blocks.map((block) => (
                <AnimatedBlock
                  key={block.number}
                  leaving={false} // No leaving animation on initial render
                >
                  <LatestBlockItem key={block.number} block={block} />
                </AnimatedBlock>
              ))}
              onSlideChanged={handleSlideChanged}
              onSlideIndexChanged={handleSlideIndexChanged}
            />
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default LatestBlock;
