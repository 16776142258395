import React from "react";
import LatestBlockTable from "./latest-block-table.component";
import BlockSetting from "./block-settings.component";

const BlockTable = ({
  blocks,
  itemsPerPage,
  totalRows,
  currentPage,
  handleChangePage,
  handleChangePageSize,
}) => {
  return (
    <>
      <LatestBlockTable blocks={blocks} />{" "}
      <BlockSetting
        itemsPerPage={itemsPerPage}
        totalRows={totalRows}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        handleChangePageSize={handleChangePageSize}
      />
    </>
  );
};

export default BlockTable;
